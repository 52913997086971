import { CatalogContextType, CategoryType, SearchFilters, UserInteractionLog } from "@tm/models"
import { ArticleListFilteredEventHelper } from "../events/article-list-filtered/event-helper"
import { PremiumUniPartsSearchEventHelper } from "../events/premium-uni-parts-search"
import { AddToBasket } from "../events/add-to-basket/event-helper"
import { getCatalogContext } from "./context"
import { TmaHelper } from "./tma"

export const SharedHelper = {
    ByArticleAndUniParts: {
        /**
         * @description use this for widgets from dash- or startpage
         */
        SetSearchContext: async (category: CategoryType, query: string, workTaskId?: string, fromWidget?: boolean) => {
            switch (category) {
                case "dtcSearch":
                case "partsIndicator":
                case "vehicleParts":
                case "directSearch":
                case "basket": {
                    return ArticleListFilteredEventHelper.ArticleListFiltered.Search.SetSearchContext(category, query, workTaskId, fromWidget)
                }

                case "universalParts":
                case "wheels":
                case "tyresTecRmi":
                case "tyres": {
                    return PremiumUniPartsSearchEventHelper.UniParts.Search.SetSearchContext(category, query, workTaskId, fromWidget)
                }
            }
        },

        GetUserInteractionLog: (positionNumber?: number): UserInteractionLog | undefined => {
            const context = getCatalogContext()

            switch (context) {

                case CatalogContextType.UniversalPartList:
                case CatalogContextType.Wheels:
                case CatalogContextType.TmTyreSearch: {
                    return AddToBasket.AddUniPartToBasket.GetUserInteractionLog(positionNumber)
                }

                case CatalogContextType.ServiceDataHaynes:
                case CatalogContextType.ServiceDataTecRmi:
                case CatalogContextType.ServiceDataAutodata:

                case CatalogContextType.TechnicalDataHaynes:
                case CatalogContextType.TechnicalDataTecRmi:
                case CatalogContextType.TechnicalDataAutodata:

                case CatalogContextType.FastDtc:

                case CatalogContextType.GpiDat:
                case CatalogContextType.DatGpiRapidCalculation:
                case CatalogContextType.GpiEurotax:
                case CatalogContextType.GpiTemot:
                case CatalogContextType.GpiTecRmi:

                case CatalogContextType.TruckVin:
                case CatalogContextType.MyTruck:
                case CatalogContextType.MyTruckPlus:
                case CatalogContextType.TruckGarage:

                case CatalogContextType.TyresTecRmi:
                case CatalogContextType.Eds:

                case CatalogContextType.DirectBuyShoppingCart:
                case CatalogContextType.CostEstimation:
                case CatalogContextType.CatalogPartList: {
                    return AddToBasket.AddCatalogPartToBasket.GetUserInteractionLog(positionNumber)
                }
            }

            return undefined
        },
        IncreaseStepNumber: async () => {
            const context = getCatalogContext()
            switch (context) {
                case CatalogContextType.CatalogPartList: {
                    ArticleListFilteredEventHelper.ArticleListFiltered.Event.IncreaseStepNumber()
                    break
                }
                case CatalogContextType.UniversalPartList: {
                    PremiumUniPartsSearchEventHelper.UniParts.Event.IncreaseStepNumber()
                    break
                }
            }
        },
        SubmitSearch: async (query: string, categoryType: CategoryType, searchFilter: SearchFilters) => {
            switch (categoryType) {
                case "directSearch":
                case "vehicleParts": {
                    ArticleListFilteredEventHelper.ArticleListFiltered.Search.Submit(query, categoryType, searchFilter)
                    break
                }
                case "universalParts":
                case "wheels":
                case "tyres": {
                    PremiumUniPartsSearchEventHelper.UniParts.Search.Submit(
                        query,
                        categoryType,
                        searchFilter
                    )
                    break
                }
            }
        },
        SearchTreeNodeSelected: async (data: {
            mode?: "fast-click" | "fast-click-2.0" | "buttons" | "hide"
            nodeId: number
            treeId?: number,
            categoryType?: CategoryType
        }) => {
            const context = getCatalogContext()
            switch (context) {
                case CatalogContextType.UniversalPartList: {
                    TmaHelper.UniParts.SearchTree.NodeSelected({
                        ...data,
                        catalogContext: context,
                    })
                    break
                }
                case CatalogContextType.CatalogPartList: {
                    TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.NodeSelected({ ...data, catalogContext: context })
                    break
                }
                default: {
                    const catalogContext =  document.location.pathname == "/" ? CatalogContextType.StartPage : CatalogContextType.Dashbord
                    switch (data.categoryType) {
                        case "vehicleParts":
                            TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.NodeSelected({
                                ...data,
                                catalogContext
                            })
                            break
                        case "universalParts":
                            TmaHelper.UniParts.SearchTree.NodeSelected({
                                ...data,
                                catalogContext,
                            })
                            break
                    }
                }
            }
        },
        SearchTreeChanged: async (treeType: CategoryType, mode?: "fast-click" | "fast-click-2.0", thumbnailClicked?: boolean) => {
            // called when (uni)article list category is opened and a node is selected, mode is awlways undefined
            switch (treeType) {
                case "directSearch":
                case "vehicleParts": {
                    ArticleListFilteredEventHelper.ArticleListFiltered.Search.TreeChanged(mode || "fast-click-2.0", thumbnailClicked)
                    break
                }
                case "universalParts": {
                    PremiumUniPartsSearchEventHelper.UniParts.Search.TreeChanged(mode || "fast-click-2.0", thumbnailClicked)
                    break
                }
            }
        },
        CategoryChanged: async (category: CategoryType) => {
            switch (category) {
                case "directSearch":
                case "vehicleParts": {
                    ArticleListFilteredEventHelper.ArticleListFiltered.Event.SwapSearchType(category)
                    break
                }
                case "universalParts": {
                    // PremiumUniPartsSearchEventHelper.UniParts.Event.Swap()

                    break
                }
            }
        },
        NodeSelected: async (props: any, mode?: "fast-click" | "fast-click-2.0", thumbnailClicked?: boolean) => {
            const context = getCatalogContext()
            console.warn("shared helper nodeselected called")
            switch (context) {
                case CatalogContextType.CatalogPartList: {
                    ArticleListFilteredEventHelper.Tree.SelectNode(props, mode || "fast-click-2.0", thumbnailClicked)
                    break
                }
                case CatalogContextType.UniversalPartList: {
                    // @ts-ignore
                    PremiumUniPartsSearchEventHelper.UniParts.SearchTree.NodeSelected({
                        mode: mode || "fast-click-2.0",
                        catalogContext: context,
                    })
                    break
                }
            }
        },
    },
}
