import { styled } from "@mui/system"
import { TreeNode } from "@tm/models"
import { getUIA } from "@tm/utils"
import { TreeCategory } from "./TreeCategory"

const OverviewGrid = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    columnGap: "1em",
    rowGap: "1em",
    padding: "1em 1em 0.5em 1em",
})

type Props = {
    initialTree: TreeNode[]
    hideSecondLevel?: boolean
    onNodeClick: (node: TreeNode) => void
}

export function SearchTreeOverview(props: Props) {
    return (
        <OverviewGrid {...getUIA("SearchtreeV2Overviewgrid")}>
            {props.initialTree.map((node) => {
                return (
                    <TreeCategory
                        key={`nodeKey_${node.id}`}
                        category={node}
                        onNodeClick={props.onNodeClick}
                        hideSecondLevel={props.hideSecondLevel}
                    />
                )
            })}
        </OverviewGrid>
    )
}
