import { forwardRef, PropsWithChildren, ReactElement } from "react"
import { Box, Dialog as MuiDialog, DialogProps, dialogClasses, styled } from "@mui/material"
import { Icon } from "../../generics/Icons"

type Props = Omit<DialogProps, "onClose"> & {
    onOutsideClick?(): void
    position?: "top" | "middle"
    skin?: "warning" | "error" | "info" | "success"
    customSkinIcon?: ReactElement
    icon?: string
    DialogActions?: ReactElement
}

const StyledDialog = styled(MuiDialog, {
    shouldForwardProp: (prop) => prop !== "position",
})<Pick<Props, "position">>(({ position }) => ({
    [`.${dialogClasses.scrollPaper}`]: {
        height: position === "top" ? "inherit" : "100%",
        maxHeight: "inherit",
    },
    [`.${dialogClasses.paper}`]: {
        margin: position === "top" ? "0" : "32px",
    },
}))

const StyledInnerDialogBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "skin",
})<Pick<Props, "skin">>(({ skin, theme }) => {
    switch (skin) {
        case "warning": {
            return {
                borderLeft: `7px solid ${theme.palette.warning.main}`,
            }
        }
        case "error": {
            return {
                borderLeft: `7px solid ${theme.palette.error.main}`,
            }
        }
        case "info": {
            return {
                borderLeft: `7px solid ${theme.palette.info.main}`,
            }
        }
        case "success": {
            return {
                borderLeft: `7px solid ${theme.palette.success.main}`,
            }
        }
        default: {
            return {}
        }
    }
})

const getMapping = (skin: "warning" | "error" | "info" | "success" | undefined) => {
    switch (skin) {
        case "warning": {
            return <Icon name="alert_B_attention" color="warning" size="24px" />
        }
        case "error": {
            return <Icon name="alert_W_warning" color="error" size="24px" />
        }
        case "success": {
            return <Icon name="alert_B_success" color="success" size="24px" />
        }
        case "info": {
            return <Icon name="alert_W_infomation" color="info" size="24px" />
        }
        default: {
            return ""
        }
    }
}

export const Dialog = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
    ({ skin, onOutsideClick, DialogActions, children, customSkinIcon, ...rest }, ref) => {
        const displayIcon = skin ? customSkinIcon || <Box mr={1.5}>{getMapping(skin)}</Box> : ""

        return (
            <>
                <StyledDialog ref={ref} {...rest} onClose={onOutsideClick}>
                    <StyledInnerDialogBox skin={skin} px={4} py={3}>
                        <Box display="flex">
                            {displayIcon ?? <Box>{displayIcon}</Box>}
                            <Box flex={1}>{children}</Box>
                            {DialogActions && <Box ml={1.5}>{DialogActions}</Box>}
                        </Box>
                    </StyledInnerDialogBox>
                </StyledDialog>
            </>
        )
    }
)
