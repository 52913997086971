import { Box } from "@mui/material"
import { ErpPrice, MemoType } from "@tm/models"
import { concat } from "@tm/utils"
import { Icon } from "../../generics/Icons"
import { Tooltip } from "../../generics/tooltip"
import { TooltipPrice } from "./components/TooltipPrice"

type Props = {
    price: ErpPrice
    additionalInfoIconSize?: string | number
}

/**
 * This component makes use of several settings in the UIConfigs from the different traders, to show their respective tooltip info for each price
 */
export function ErpPriceAdditionalInfo({ price, additionalInfoIconSize = 16 }: Props) {
    // const { translateText } = useLocalization()

    /** @todo Check if we really need these two parameters anymore --> NEXT-26321 */
    // const { hideEkTooltip, showVkAndRebateInEkPriceTooltip } = Morpheus.getParams<ErpBundleParams>("erp")

    // const articleErpInfo = useContextSelector(ArticleItemContext, (x) => x.articleErpInfo)
    // const prices = articleErpInfo?.state === "success" ? articleErpInfo.response?.prices : undefined

    // const additionalPrice = useMemo(() => {
    //     if (!showVkAndRebateInEkPriceTooltip || !prices) {
    //         return
    //     }

    //     const retailPrice = getPrice(prices, PriceType.Retail) ?? getPrice(prices, PriceType.RecommendedRetail)

    //     if (!retailPrice) {
    //         return
    //     }

    //     return {
    //         ...retailPrice,
    //         description: retailPrice.description ?? translateText(retailPrice.type === PriceType.Retail ? 1620 : 1144),
    //     }
    // }, [prices, showVkAndRebateInEkPriceTooltip, translateText])

    function renderAdditionalDescriptions() {
        const additionalDescriptions = price.memos?.filter((x) => x.type === MemoType.AdditionalDescription) ?? []

        if (!additionalDescriptions.length) {
            return
        }

        return <Box>{`${concat("\n", ...additionalDescriptions.map((x) => x.text))}\n`}</Box>
    }

    function renderTooltipContent() {
        return (
            <Box sx={{ whiteSpace: "pre-line" }}>
                {renderAdditionalDescriptions()}
                {/* {additionalPrice && <TooltipPrice price={additionalPrice} />} */}
                <TooltipPrice price={price} />
            </Box>
        )
    }

    const tooltipContent = renderTooltipContent()
    // switch (price.type) {
    //     case PriceType.Purchase:
    //         // if (hideEkTooltip) {
    //         //     return null
    //         // }

    //         content = renderTooltipContent()
    //         break
    //     case PriceType.Retail:
    //         content = renderTooltipContent()
    //         break
    //     default:
    //         return null
    // }

    return (
        <Tooltip title={tooltipContent} noDelay>
            <Icon size={`${additionalInfoIconSize}px`} name="info" sx={{ display: "block" }} />
        </Tooltip>
    )
}
