import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useLocalization } from "@tm/localization"
import { Tip } from "../../Tip"
import { FastClickBadge } from "../FastClickBadge/FastClickBadge"

export const SearchtreeTip = () => {
    const { translateText } = useLocalization()
    const tipString = translateText(1040).split("{0}")
    return (
        <Tip
            text={
                <Box>
                    <Typography variant="body1" color="white" component="span">
                        {tipString[0]}
                        <FastClickBadge sx={{ display: "inline-flex" }} mx="3px" />
                        {tipString[1]}
                    </Typography>
                </Box>
            }
        />
    )
}
