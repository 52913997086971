import { SearchExecutedRequest, ArticleListInfo } from "./models"
import { AnalyticsEventTriggerType, CategoryType, ECatalogFeature, GetArticlesRequest, getCurrentWorkTaskId, GetUniArticlesResponse } from "@tm/models"
import { TmaSender } from "../tma-sender"
import { TmaEvent, TrackingModel } from "../../models/models"
import { CatalogContextType } from "@tm/models"
import { uniqueId } from "../../../helpers/id"
import { ProductGroupDataSupplier } from "../article-list-filtered/models"
import { getPageIndexMax } from "../article-list-filtered/helper"
import { getCatalogContext } from "../../helper/context"
import { Article } from "@tm/models"

export class PremiumUniPartsSearchEvent extends TmaSender<SearchExecutedRequest> implements TmaEvent {
    static serviceEndpoint = "/uniArticles/searchExecuted"

    key = "PREMIUM_UNI_PARTS_SEARCH"

    workTaskId: string
    autocompleteResults: Array<string>

    constructor() {
        // extending tma sender needs to set
        super(PremiumUniPartsSearchEvent.serviceEndpoint)

        this.workTaskId = ""
        this.autocompleteResults = []

        this.reset()
    }

    reset = () => {
        this.resetEvent({
            criterionFilters: [],
            articleList: {
                articleCount: 0,
                paging: {
                    pageIndex: undefined,
                    pageIndexMax: undefined,
                    pageSize: undefined,
                },
                productGroupDataSuppliers: [],
                wholesalerArticleCount: 0,
                maxArticleCount: undefined,
            },
            origin: {
                context: CatalogContextType.None,
                trigger: AnalyticsEventTriggerType.None,
                feature: ECatalogFeature.None
            },
            searchStep: {
                number: 1,
                processId: uniqueId(),
            },
            searchTree: {},
            query: undefined,
        })
    }


    public handle = (trackingData: TrackingModel<any>): boolean => {
        if (!trackingData.tmaEvent || trackingData.tmaEvent !== "PREMIUM_UNI_PARTS_SEARCH" || !trackingData.payload) {
            return false
        }

        // when the event doesnt have a searchstep it was called from the dashboard with no worktask id.
        if (!this.requestBody.searchStep) {
            this.requestBody = {
                ...this.requestBody,
                searchStep: {
                    number: 1,
                    processId: uniqueId()
                },
                origin: {
                    context: CatalogContextType.None,
                    trigger: AnalyticsEventTriggerType.None,
                    feature: ECatalogFeature.None
                }
            }
        }

        switch (trackingData.bundle) {
            case "parts":
            case "api": {
                this.workTaskId = trackingData.payload.workTaskId ?? getCurrentWorkTaskId()

                if (Array.isArray(trackingData.action)) {
                    trackingData.action.forEach((action) => this.fillRequest(trackingData, action))
                } else {
                    this.fillRequest(trackingData, trackingData.action)
                }

                return true
            }
            case "worktask": {
                if (trackingData.action == "reset-process-id") {
                    if (Array.isArray(trackingData.payload.worktaskIds) && trackingData.payload.worktaskIds.indexOf(this.workTaskId) >= 0) {
                        this.reset()
                    }
                }
                return true
            }

            default:
                return false
        }
    }

    private fillRequest(trackingData: TrackingModel<any>, action: string) {
        const payload = trackingData.payload

        switch (action) {
            case "articlesLoaded": {
                const { request, result } = payload

                if (this.requestBody?.origin?.context === CatalogContextType.UniversalPartList && this.requestBody?.origin?.trigger == AnalyticsEventTriggerType.None) {
                    this.requestBody.origin.trigger = AnalyticsEventTriggerType.FastClickV2
                } else if (this.requestBody?.origin?.context == CatalogContextType.None) {
                    this.requestBody.origin = {
                        context: getCatalogContext(),
                        trigger: AnalyticsEventTriggerType.None
                    }
                }

                if (request.query) {
                    this.attachSearchQuery(request.query)
                }
                this.attachArticleListInfo(request, result)
                this.sendEvent()
                break
            }

            case "autocomplete": {
                const { hits } = payload

                if (hits && Array.isArray(hits)) {
                    this.autocompleteResults = hits
                }

                // if (this.requestBody.origin && trackingData.source == "product-group-search") {
                //     this.requestBody.origin.trigger = AnalyticsEventTriggerType.ProductGroupKeywordSearch
                // }

                break
            }
            case "nodeSelected":
            case "handleChangeBreadcrumbs": {
                this.reset()

                const { origin } = this.requestBody
                const { mode, catalogContext, nodeId, treeId } = payload

                if (origin && mode) {
                    switch (mode) {
                        case "fast-click": {
                            origin.trigger = AnalyticsEventTriggerType.FastClickV1
                            break
                        }

                        case "fast-click-2.0":
                        default: {
                            origin.trigger = AnalyticsEventTriggerType.FastClickV2
                            break
                        }
                    }

                    origin.feature = ECatalogFeature.UniversalPartSearch
                    origin.context = catalogContext || CatalogContextType.UniversalPartList

                    this.requestBody.searchTree.nodeId = nodeId
                    this.requestBody.searchTree.treeId = treeId
                }
                break
            }

            case "handleSearch": {
                const { query, categoryType } = payload

                this.reset()
                this.setContextAndTrigger(categoryType)

                this.attachSearchQuery(query)

                break
            }

            case "handleExternalSearch": {
                const { query } = payload

                this.reset()

                this.attachSearchQuery(query)

                this.requestBody.origin.context = getCatalogContext()
                this.requestBody.origin.trigger = AnalyticsEventTriggerType.None

                break
            }

            case "increase-step-number": {
                this.requestBody.searchStep = {
                    number: !this.requestBody.searchStep ? 1 : ++this.requestBody.searchStep.number,
                    processId: !this.requestBody.searchStep ? uniqueId() : this.requestBody.searchStep.processId
                }
                break
            }

            case "filterChanged": {
                if (payload.criterionFilters) {
                    this.requestBody.criterionFilters = payload.criterionFilters
                }
                break
            }

            case "keyword-search-context": {
                const { categoryType, query, fromWidget } = payload

                this.reset()

                this.setContextAndTrigger(categoryType, fromWidget)

                this.attachSearchQuery(query)

                break
            }

            case "reset": {
                // this.reset()
                break
            }
        }

        // TODO: @Christopher Erdmann change the save to sessionstorage behavior
        // this is necessary because changing properties on an object with a custom setter will not trigger set.
        // this has to be rewritten like articleListFiltered, where a tmpRequestBody is stored in the context
        // changes to this tmpRequestBody will be stored inside the sessionstorage. (ofc load from  sessionstorage)
        this.requestBody = this.requestBody
    }

    private attachSearchQuery = (query: string) => {
        let searchQuery: string | undefined = query

        if (!searchQuery) {
            const params = window.location.search.split("&")
            params.forEach((param) => {
                const regexMatch = /query=(.*)/.exec(param)
                if (regexMatch && regexMatch[1]) {
                    searchQuery = decodeURIComponent(regexMatch[1]).replace(/\+/g, " ")
                }
            })
        }

        if (!searchQuery || searchQuery == "") {
            this.requestBody.query = undefined
            return
        }

        this.requestBody.query = searchQuery
    }

    attachArticleListInfo(request: GetArticlesRequest, result: GetUniArticlesResponse) {
        let wholesalerArticleCount = 0
        const productGroupDataSuppliers: Array<ProductGroupDataSupplier> = []

        result?.uniArticles?.forEach((article: Article) => {
            if (article.traderArticleNo) {
                ++wholesalerArticleCount
            }

            if (article.supplier) {
                const { supplier, productGroup } = article
                const productGroupDataSupplierPair = {
                    dataSupplierId: supplier.id,
                    productGroupId: productGroup.id,
                }

                if (
                    !productGroupDataSuppliers.find(
                        (item) =>
                            item.dataSupplierId == productGroupDataSupplierPair.dataSupplierId &&
                            item.productGroupId == productGroupDataSupplierPair.productGroupId
                    )
                ) {
                    productGroupDataSuppliers.push(productGroupDataSupplierPair)
                }
            }
        })

        const maxArticleCount = result?.articleListCount ?? 0
        const pageSize = result?.pageSize ?? request.pageSize
        const pageIndexMax = getPageIndexMax(maxArticleCount, pageSize)

        const articleListInfo: ArticleListInfo = {
            articleCount: result?.uniArticles?.length ?? 0,
            maxArticleCount,
            paging: {
                pageIndex: result?.pageIndex ?? request.pageIndex,
                pageSize,
                pageIndexMax,
            },
            wholesalerArticleCount,
            productGroupDataSuppliers,
        }
        this.requestBody.articleList = articleListInfo
    }

    private setContextAndTrigger(categoryType?: CategoryType, fromWidget?: boolean) {
        let context: CatalogContextType = CatalogContextType.None
        let trigger: AnalyticsEventTriggerType = AnalyticsEventTriggerType.None

        switch (categoryType) {
            case "wheels": {
                context = CatalogContextType.Wheels
                break
            }

            case "tyres": {
                context = CatalogContextType.TmTyreSearch
                break
            }

            case "tyresTecRmi": {
                context = CatalogContextType.TyresTecRmi
                break
            }

            case "basket": {
                context = getCatalogContext()
                break
            }

            case "universalParts": {
                if (fromWidget) {
                    context = document.location.pathname == "/" ? CatalogContextType.StartPage : CatalogContextType.Dashbord
                } else {
                    context = CatalogContextType.UniversalPartList
                }
                trigger = AnalyticsEventTriggerType.UniversalPartKeywordSearch
                break
            }
            default: {
                context = CatalogContextType.UniversalPartList
                trigger = AnalyticsEventTriggerType.UniversalPartKeywordSearch
                break
            }
        }

        this.requestBody.origin.context = context
        this.requestBody.origin.trigger = trigger
        this.requestBody.origin.feature = ECatalogFeature.UniversalPartSearch
    }
}
