import { createElement, ReactElement, ReactChild, ReactFragment } from "react"
import { showTextIds } from "../createLocalizationProvider"
import { LocalizationConfig } from "../models";

const translationRegex = /\{\{(.*?)\}\}/g

export function isTranslationId(key: string) {
    translationRegex.lastIndex = 0
    const regExMatch = translationRegex.test(key);
    translationRegex.lastIndex = 0
    return regExMatch;
}

export function translate(config: LocalizationConfig, key: number | string, jsxAutoMode: boolean = true): ReactChild | ReactFragment {
    if (!config || !config.translation) {
        return `[${key}]`
    }

    if (typeof (key) === "string" && translationRegex.test(key)) {
        return translateExpression(config, key, jsxAutoMode)
    }

    return translateKey(config, key, jsxAutoMode)
}

export function translateText(config: LocalizationConfig, key: number | string): string {
    return translate(config, key, false) as string
}

function translateExpression(config: LocalizationConfig, key: string, jsxAutoMode: boolean = true): ReactChild | ReactFragment {
    translationRegex.lastIndex = 0 // due to the usage of the /g Flag  on multiple tests we need to reset the last index manually
    if (translationRegex.test(key)) {
        const translatedKey = key.replace(translationRegex,
            (_: string, num: number) => getTranslationFromConfig(config, num)
        )

        return !jsxAutoMode ? translatedKey : createJsxElements(translatedKey)
    }

    return `[${key}]`
}
function translateKey(config: LocalizationConfig, key: number | string, jsxAutoMode: boolean = true): ReactChild | ReactFragment {
    const translation = getTranslationFromConfig(config, key)
    if (!jsxAutoMode) {
        return translation
    }

    return !jsxAutoMode ? translation : createJsxElements(translation)
}

function getTranslationFromConfig(config: LocalizationConfig, key: number | string) {
    const translation = config.translation[key]
    if (!translation)
        return "[" + key + "]"
    else if (showTextIds)
        return "#" + key + " " + translation
    else
        return translation
}

function createJsxElements(translation: string): ReactChild | ReactFragment {
    const tokens: Array<ReactElement<any>> = []
    let i: number = 0


    // maybe it's intended to always add a <break/> even when there is no \n
    // if(translation.indexOf("\n") >= 0) {
    translation.split("\n").forEach(text => {
        text && tokens.push(createElement("span", { key: i++ }, text))
        tokens.push(createElement("br", { key: i++ }))
    })
    // }else {
    //     tokens.push(createElement("span", { key: i++ }, translation))
    // }

    tokens.splice(tokens.length - 1, 1)

    return tokens.length == 1 ?
        tokens[0].props.children :
        createElement("span", undefined, tokens)
}
