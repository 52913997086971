const patternEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export class FieldValidation {
    private _value: any

    private _messages: Array<string>

    private _valid: boolean

    constructor(value: any) {
        this._value = value
        this._messages = []
        this._valid = true
    }

    get messages() {
        return this._messages
    }

    get isValid() {
        return this._valid
    }

    required(msg: string): FieldValidation {
        if (!this._value) {
            this._valid = false
            this._messages.push(msg)
        }
        return this
    }

    noDigits(msg: string): FieldValidation {
        if (/\d/.test(this._value)) {
            this._valid = false
            this._messages.push(msg)
        }
        return this
    }

    min(min: number, msg: string): FieldValidation {
        if (!this._value || this._value.trim().length < min) {
            this._valid = false
            this._messages.push(msg.replace("#1#", min.toString()))
        }
        return this
    }

    max(max: number, msg: string): FieldValidation {
        if (this._value && this._value.trim().length > max) {
            this._valid = false
            this._messages.push(msg.replace("#1#", max.toString()))
        }
        return this
    }

    email(msg: string): FieldValidation {
        if (this._value && !patternEmail.test(this._value)) {
            this._valid = false
            this._messages.push(msg)
        }
        return this
    }

    regExp(regExp: RegExp, msg: string): FieldValidation {
        if (!regExp.test(this._value)) {
            this._valid = false
            this._messages.push(msg)
        }
        return this
    }
}

export function validateField(value: any): FieldValidation {
    return new FieldValidation(value)
}
