import { TmaSender } from "../tma-sender"
import { LoginType, LoggedInRequest, NetworkType } from "./models"
import { TmaEvent, BaseTrackingModel } from "../../models/models"
import { BrowserInfo } from "../../../helpers/browser-info"
import isMobile from "is-mobile"

export class LoggedIn extends TmaSender<LoggedInRequest> implements TmaEvent {
    key = "LOGGED_IN"

    // requestBody: LoggedInRequest = {}
    constructor() {
        // when extending tma sender we need to set the endpoint
        super("/logins/loggedin")
        this.requestBody = {}
    }

    handle = (trackingData: BaseTrackingModel<string>): Promise<void> | void => {
        if (trackingData.tmaEvent && trackingData.tmaEvent.toLocaleLowerCase() != this.key.toLocaleLowerCase()) {
            return
        }

        if (trackingData.payload) {
            this.requestBody.loginType = trackingData.payload == "hidden" ? LoginType.hidden : LoginType.form
        }

        if (BrowserInfo) {
            const browser = BrowserInfo.getBrowser()
            const os = BrowserInfo.getOS()
            const features = BrowserInfo.getBrowserFeatures()

            this.requestBody.clientInfo = {
                ApplicationVersion: (window as any).__version || "",
                Browser: browser.name,
                BrowserVersion: browser.versionString,
                CookiesEnabled: features.allowsCookies,
                ScreenHeight: features.desktop.height,
                ScreenWidth: features.desktop.width,
                ViewportHeight: features.window.height,
                ViewportWidth: features.window.width,
                OperatingSystem: os.name,
                OperatingSystemVersion: os.versionString,
                JavascriptEnabled: true,

                DotNetFrameworkVersion: undefined,
                DriveSpaceFree: undefined,
                DriveSpaceTotal: undefined,
                GraphicCard: getGraphicCard(),
                NetworkType: checkNetworkType(),
                ProcessorType: undefined, // we can get logical cores
                RamFree: undefined,
                RamTotal: undefined, // we can get an approximately value, in my case: i have 20gb ram but it will show me 8gib
            }
        }

        if (window.tmJSShellMeta) {
            this.requestBody.shellMeta = window.tmJSShellMeta
        }

        if (this.requestBody.clientInfo && this.requestBody.loginType !== undefined) {
            return this.sendEvent()
        }
    }
}
function checkNetworkType() {
    if (isMobile()) {
        return NetworkType.mobile
    }

    const info = (window.navigator as any).connection
    if (!info || !info.type) {
        return undefined
    }

    switch (info.type) {
        case "wifi": {
            return NetworkType.wifi
        }
        case "ethernet": {
            return NetworkType.lan
        }
        default: {
            return undefined
        }
    }
}

function getGraphicCard() {
    const canvas = document.createElement("canvas")

    let context
    try {
        context = canvas.getContext("webgl") || canvas.getContext("experimental-webgl")
    } catch (e) {
        return undefined
    }

    const debugInfo = (context as WebGLRenderingContext)?.getExtension("WEBGL_debug_renderer_info")
    if (debugInfo) {
        // vendor = (context as WebGLRenderingContext).getParameter(debugInfo.UNMASKED_VENDOR_WEBGL)
        const renderer = (context as WebGLRenderingContext).getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
        return renderer
    }

    return undefined
}
