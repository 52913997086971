import { Box, Typography } from "@mui/material"
import { styled } from "@mui/system"
import { TreeNode as TreeNodeModel } from "@tm/models"
import { getUIA } from "@tm/utils"
import { TreeNode } from "./TreeNode"

const Wrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
})

type Props = {
    category: TreeNodeModel
    onNodeClick: (node: TreeNodeModel) => void
    hideSecondLevel?: boolean
}

export function TreeCategory(props: Props) {
    const {
        hideSecondLevel,
        category,
        category: { childNodes = [] },
    } = props
    return (
        <Wrapper sx={{ overflow: "hidden" }}>
            <Box pb="6px">
                {hideSecondLevel ? (
                    <TreeNode key={category.id} node={category} onClick={props.onNodeClick} />
                ) : (
                    <Typography variant="h3" sx={{ fontSize: "18px" }}>
                        {category.name}
                    </Typography>
                )}
            </Box>
            {!hideSecondLevel && (
                <Box display="flex" ml="5px" flexDirection="column" {...getUIA("SearchtreeV2TreeNodeContainer", category.id)}>
                    {childNodes.map((node) => {
                        return <TreeNode key={node.id} node={node} onClick={props.onNodeClick} />
                    })}
                </Box>
            )}
        </Wrapper>
    )
}
