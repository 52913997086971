export enum ECalcOrigin {
    RD = 1,
    Apps = 2,
    Next = 4,
    FastGap = 8,
    SvcConnect = 16,
    Workshop = 32,
    Searchtree = 64,
    Characteristics = 128,
    FastService = 256,
    FastCalcManager = 512,
    FastCheck = 1024,
    FastDTC = 2048,
}
