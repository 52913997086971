import { FC } from "react"
import { TooltipProps as MuiTooltipProps, styled, tooltipClasses } from "@mui/material"
import { TooltipComponent } from "./component"

export interface TooltipProps extends MuiTooltipProps {
    variant?: "dark" | "light"
    color?: "primary"
    width?: number
    preventCloseOnScroll?: boolean
    onClickAway?: (event: MouseEvent | TouchEvent | WheelEvent) => void
    withoutBordercolor?: boolean
    noDelay?: boolean
}

export const Tooltip: FC<TooltipProps> = styled(TooltipComponent, {
    shouldForwardProp: (prop: string) => !["variant", "color", "width", "withoutBordercolor"].includes(prop),
})<TooltipProps>(({ theme, variant = "dark", color, width, withoutBordercolor }) => {
    const colorDark = theme.palette.grey[800]
    let backgroundColor = colorDark
    let borderColor = colorDark
    if (variant === "light") {
        // eslint-disable-next-line prefer-destructuring
        backgroundColor = theme.palette.grey[50]
    }
    if (color === "primary") {
        borderColor = theme.palette.primary.main
    }
    return {
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: width,
            backgroundColor,
            color: theme.palette.getContrastText(backgroundColor),
            fontSize: theme.font?.textSize?.m || "1rem",
            ...(!withoutBordercolor && {
                border: `1px solid ${borderColor}`,
            }),
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: withoutBordercolor ? backgroundColor : borderColor,
        },
    }
})
