import { PremiumUniPartsSearchEvent } from "./event-handle"
import { Filter } from "../../models/models"

export * from "./event-helper"
export * from "./models"

export type PremiumUniPartsSearchTrackEvent = {
    action: string // "parts-search"
    bundle: string // "parts"
    component: string // "tree"
    initiateProcess: boolean // true
    payload: any // (4) [{…}, {…}, {…}, {…}]
    source: string // "searchtree"
    step: number // 1
    tmaEvent: string // "ARTICLE_LIST_FILTERED"
    type: string // "search"
}

/**
 * Data which is needed to be stored in order to augment the tma event data
 * add more properties if needed
 */
export type AnalyticsData = {
    filter?: Filters
}

export type Filters = {
    // selected?: AvailableData,
    available?: AvailableData
}
export type SelectedData = {
    productGroupIds?: Array<number>
    supplierIds?: Array<number>
    oeReferences?: Array<number>
}

export type AvailableData = {
    productGroupFilters?: Array<Filter>
    dataSupplierFilters?: Array<Filter>
    oeReferences?: Array<Filter>
}

export default new PremiumUniPartsSearchEvent()
