import { useEffect, useRef } from "react"

export * from "./articleNotes"
export * from "./articleDetails"
export * from "./authority"
export * from "./dashboard/multiWidget"
export * from "./erp"
export * from "./memoTool"
export * from "./parts"
export * from "./postMessages"
export * from "./resize"
export * from "./statemanagement"
export * from "./useSelection"
export * from "./useSessionStorage"
export * from "./useLocalStorage"
export * from "./vatRate"
export * from "./vehicle"
export * from "./worktask"

/** https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state */
export function usePrevious<T>(value: T) {
    const ref = useRef<T>()
    useEffect(() => {
        ref.current = value
    }, [value])
    return ref.current
}
