import { AnalyticsEventTriggerType, CatalogContextType, ECatalogFeature, UserInteractionLog } from "@tm/models"
import { getCatalogContext } from "../../helper/context"
import { TmaEventTracking } from "../.."
import { uniqueId } from "../../.."
import { AddToBasketTrigger } from "./models"

export interface ArticleListViewOptions {
    compactView: boolean
    showArticleImages: boolean
    showVehicleRecordsFilters: boolean
}

export const AddToBasket = {
    AddCatalogPartToBasket: {
        GetUserInteractionLog: (positionNumber?: number): UserInteractionLog | undefined => {
            const request = TmaEventTracking.getRequestData("ARTICLE_LIST_FILTERED")

            if (request?.searchStep) {
                const { context, trigger, feature } = request.origin

                const log: UserInteractionLog = {
                    eventId: uniqueId(),
                    id: request.searchStep!.processId,
                    stepNumber: request.searchStep!.number,
                    fromPageNumber: request.articleList?.paging.pageIndex,
                    fromPositionNumber: positionNumber,
                    catalogContext: context == CatalogContextType.None ? getCatalogContext() : context,
                    trigger: trigger || AnalyticsEventTriggerType.None,
                    feature:  feature || ECatalogFeature.None,
                    foundByQuery: request.querySearch?.query,
                }
                return log
            }

            return undefined
        },
    },
    AddUniPartToBasket: {
        GetUserInteractionLog: (positionNumber?: number): UserInteractionLog | undefined => {
            const request = TmaEventTracking.getRequestData("PREMIUM_UNI_PARTS_SEARCH")

            if (request?.searchStep) {
                const { context, trigger, feature } = request.origin

                const log: UserInteractionLog = {
                    eventId: uniqueId(),
                    id: request.searchStep!.processId,
                    stepNumber: request.searchStep!.number,
                    fromPageNumber: request.articleList?.paging.pageIndex,
                    fromPositionNumber: positionNumber,
                    catalogContext: context == CatalogContextType.None ? getCatalogContext() : context,
                    trigger: trigger || AnalyticsEventTriggerType.None,
                    feature:  feature || ECatalogFeature.None,
                    foundByQuery: request.query,
                }
                return log
            }

            return undefined
        },
    },
    AddCustomToBasket: {
        GetUserInteractionLog: (addToBasketTrigger?: AddToBasketTrigger): UserInteractionLog => {

            const catalogContext = getCatalogContext()
            let trigger: AnalyticsEventTriggerType = AnalyticsEventTriggerType.None

            switch (addToBasketTrigger) {
                case "customArticle":
                    trigger = AnalyticsEventTriggerType.CustomArticle
                    break

                case "wholesalerPartList":
                    // If we have other cases, it needs to be added here
                    switch (catalogContext) {
                        // FileImportBasket is called only in basket and we don't have any flags
                        case CatalogContextType.DirectBuyShoppingCart:
                            trigger = AnalyticsEventTriggerType.FileImportBasket
                            break
                    }
                    break
            }

            return {
                eventId: uniqueId(),
                id: uniqueId(),
                catalogContext,
                trigger
            }
        }
    },
}
