export type ModuleInfo = {
    id: string
    icon?: string | false
    name: string
    view?: string | false
    info?: string | false
}

/***
 * Renamed to ModuleTab
 * @deprecated Use ModuleTab instead
 */
export type ModuleOpened = ModuleTab

/***
 * @property {ModuleConflictType} firstAttachConflictType  - If value is set will show conflict dialog
 *  first time a vehicle or customer is attached to a worktask, see ModuleConflictType enum for options
 */
export type ModuleTab = {
    key?: string // A unique key identifying the tab/module type. Used for tab comparison
    icon?: string
    title: string
    url: string
    isSelected: boolean
    isWorkTask?: boolean
    isDefault?: boolean
    needsVehicle?: boolean
    preventURLRewrite?: boolean
    firstAttachConflictType?: ModuleConflictType
}

export enum ModuleConflictType {
    Vehicle = "VEHICLE",
    Customer = "CUSTOMER",
    CustomerAndVehicle = "CUSTOMER_AND_VEHICLE"
}