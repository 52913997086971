export function copyToClipboard(text: string): Promise<void> {
    if (typeof tmJSEvents === "undefined" && navigator.clipboard) {
        return navigator.clipboard.writeText(text)
    }

    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    let success = true
    try {
        document.execCommand("copy")
    } catch (err) {
        success = false
        console.error("Fallback: Unable to copy to clipboard: ", err)
    }

    document.body.removeChild(textArea)

    return success ? Promise.resolve() : Promise.reject()
}
