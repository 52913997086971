import { History, Location, createMemoryHistory } from "history"
import * as PropTypes from "prop-types"
import { Component, ComponentClass, ComponentElement, ComponentType, ReactElement, ReactNode, createElement } from "react"
import { RouteComponentProps, Router, RouterProps, withRouter } from "react-router"
import { Store } from "redux"
import { Subject } from "rxjs"
import "./helpers"
import { LayoutProps } from "./layout"
import { createModule } from "./lib/createModule"
import { AssetLoadProgress, AssetLoadProgressHandler, loadAssets } from "./lib/loadAssets"
import { renderTemplate } from "./lib/renderTemplate"
import { BundleMicro, IBundle, IPlugin, TemplateRenderer } from "./models/bundle"
import { ChildConfig, Configuration } from "./models/configuration"
import { Dictionary } from "./models/dictionary"
import { Transition } from "./models/transition"
import { Transmission } from "./models/transmission"
import { ModuleProps } from "./module"

export * from "./connectComponent"
export * from "./helpers/useActions"
export * from "./helpers/useMicro"
export * from "./helpers/withMicro"
export * from "./layout"
export * from "./lib/createComponent"
export * from "./lib/loadAssets"
export * from "./lib/withTemplate"
export * from "./models/bundle"
export * from "./models/configuration"
export * from "./models/dictionary"
export * from "./models/props"
export * from "./models/redux"
export * from "./models/transition"
export * from "./models/transmission"
export * from "./module"


const VIEW_URL_SPLIT_CHAR = "^"

export interface IContext {
    rootActions$: Subject<Transmission>
    pathPrefix: string
    config: Configuration
    plugins: Array<[IPlugin<ChildConfig>, Dictionary<any>]>
    bundles: Dictionary<IBundle>
    layouts: Dictionary<ComponentClass<LayoutProps>>
    modules: Dictionary<ComponentElement<ModuleProps, any>>
    transitions: Array<Transition>
    stores: Dictionary<Store<any>>
    multiStores: Dictionary<Store<any>>
    errorComponent?: ComponentType
    assetLoadProgresses: Array<AssetLoadProgress>
    assetLoadCount: number
    routes: { path: string, exact: boolean }[]
}

let mainHistory: History
let views: {
    [name: string]: {
        listener: ViewListener
        urlSplitPosition: number
    }
}

let context: IContext

export const errorListener: Array<(error: any) => void> = []
export default class Morpheus {

    context: IContext
    onBundleInit?: (bundle: IBundle) => void
    onReady?: (stream: Subject<Transmission>) => void
    mainModule: ReactNode

    constructor(config: Configuration, bundles: Dictionary<IBundle> = {}, layouts: Dictionary<ComponentClass<LayoutProps>> = {}, transitions: Array<Transition> = [], onBundleInit?: (bundle: IBundle) => void, onReady?: (stream: Subject<Transmission>) => void, errorComponent?: ComponentType) {
        // replace tilde in stylesUrl
        Object.keys(bundles).forEach(key => {
            const bundle = bundles[key]
            if (bundle.stylesUrl && bundle.stylesUrl.indexOf("~") === 0) {
                bundle.stylesUrl = bundle.stylesUrl.substr(1)
            }
        })

        context = (window as any)["__MORPHEUS_CONTEXT__"] = this.context = {
            rootActions$: new Subject<Transmission>(),
            pathPrefix: "",
            config,
            bundles,
            layouts,
            plugins: [],
            modules: {},
            transitions: transitions || [],
            stores: {},
            multiStores: {},
            errorComponent,
            assetLoadProgresses: [],
            assetLoadCount: 0,
            routes: []
        }
        this.onBundleInit = onBundleInit
        this.onReady = onReady
        views = {}
    }

    loadAssets<T>(onProgress?: AssetLoadProgressHandler) {
        return loadAssets(this.context, onProgress).then(() => {
            Object.keys(this.context.bundles).forEach(key => {
                const bundle = this.context.bundles[key]
                bundle.components && bundle.components.forEach(component => {
                    if (component.reducer) console.warn(`${bundle.name}/${component.name}: reducer() is deprecated, please use reduce() instead.`)
                    if (component.receiver) console.warn(`${bundle.name}/${component.name}: receiver() is deprecated, please use receive() instead.`)
                    if (component.transmitFilter) console.warn(`${bundle.name}/${component.name}: transmitFilter() is deprecated, please use transmit() instead.`)
                })
                if ((bundle as any).partials) console.warn(`${bundle.name}: partials are deprecated, please use micros instead.`)
                const micros = bundle.micros || (bundle as any).partials as Array<BundleMicro<any>>
                micros && micros.forEach(micro => {
                    if (micro.reducer) console.warn(`${bundle.name}/${micro.name}: reducer() is deprecated, please use reduce() instead.`)
                })
            })
        })
    }

    render(history: History, pathPrefix?: string): ReactNode {
        mainHistory = history

        const { config, bundles, rootActions$ } = this.context

        if (pathPrefix) {
            const match = /^\/?(.+)\/?$/.exec(pathPrefix)
            if (match) {
                this.context.pathPrefix = `/${match[1]}`
            }
        }

        if (!config.modules) {
            console.info("No module configured.")
            return createElement(Router, { history })
        }

        if (!config.main) {
            console.error("Parameter 'main' needs to be set.")
            return createElement(Router, { history })
        }

        const mainConfig = config.modules[config.main]
        if (!mainConfig) {
            console.error(`The main module '${config.main} can't be found.`)
            return createElement(Router, { history })
        }

        // Initialize bundles with parameters before creating the modules (and the redux stores)
        // because the config values could be required for setting the default state.
        Object.entries(bundles).forEach(([key, bundle]) => {
            const bundleConf = config.bundles[key]

            if (bundle && bundleConf) {
                const initializedConfigParams = bundle.init(bundleConf.params)
                if (initializedConfigParams) {
                    config.bundles[key].params = initializedConfigParams
                }
            }

            this.onBundleInit?.(bundle)
        })

        this.mainModule = createModule(this.context, config.main, mainConfig, rootActions$)

        //get all plugins
        if (config.plugins) {
            Object.keys(config.plugins).forEach(pluginKey => {
                const pluginConfig = config.plugins![pluginKey]
                const bundle = context.bundles[pluginConfig.bundle]
                const plugin = (bundle?.plugins || []).find(x => x.name == pluginConfig.name)

                if (!plugin) {
                    console.warn(`Plugin ${pluginConfig.name} of bundle ${pluginConfig.bundle} not found.`)
                    return
                }

                context.plugins.push([plugin, pluginConfig.props || {}])
            })
        }

        this.onReady && this.onReady(this.context.rootActions$)

        if ((window as any).__NEXT_DEVTOOLS_EXTENSION__?.enabled) {
            (window as any).__NEXT_DEVTOOLS_EXTENSION__.getContext = () => context
        }
        return this.mainModule
    }

    createView(name: string, listener: ViewListener): ReactElement<RouterProps> {

        const paramKey = `(${name})`


        const params = new URLSearchParams(mainHistory.location.search)
        const viewUrl = params.get(paramKey)

        listener(viewUrl ? "OPEN" : "CLOSE")

        const urlSplitPosition = viewUrl ? viewUrl.indexOf(VIEW_URL_SPLIT_CHAR) : 0
        views[name] = {
            listener,
            urlSplitPosition
        }

        const viewHistory = createMemoryHistory({
            initialEntries: viewUrl ? [viewUrl.replace(VIEW_URL_SPLIT_CHAR, "")] : undefined,
            initialIndex: 0
        })

        mainHistory.listen((location: Location) => {
            const params = new URLSearchParams(location.search)
            let viewUrl = params.get(paramKey)
            if (!viewUrl) {
                listener("CLOSE")
                return
            }
            views[name].urlSplitPosition = viewUrl.indexOf(VIEW_URL_SPLIT_CHAR)

            // Check if viewHistory is up to date
            viewUrl = viewUrl.replace(VIEW_URL_SPLIT_CHAR, "")
            if (viewHistory.location.pathname + viewHistory.location.search != viewUrl) {
                viewHistory.push(viewUrl.replace(VIEW_URL_SPLIT_CHAR, ""))
            }

            listener("OPEN")
        })

        viewHistory.listen((location: Location) => {
            if (viewHistory.action !== "PUSH" && viewHistory.action !== "REPLACE") {
                return
            }
            const pos = views[name].urlSplitPosition
            const mainHistoryParams = new URLSearchParams(mainHistory.location.search)
            let mainHistoryViewPath = mainHistoryParams.get(paramKey) || ""
            mainHistoryViewPath = `${mainHistoryViewPath.substr(0, pos)}${mainHistoryViewPath.substr(pos + 1)}`
            // Check if the mainHistory is up to date
            if (mainHistoryViewPath != location.pathname + location.search) {
                const path = location.pathname
                mainHistoryParams.set(paramKey, `${path.substr(0, pos)}${VIEW_URL_SPLIT_CHAR}${path.substr(pos)}${(location.search || "")}`)
                const url = `${mainHistory.location.pathname}?${mainHistoryParams.toString()}`

                switch (viewHistory.action) {
                    case "PUSH":
                        mainHistory.push(url)
                        break
                    case "REPLACE":
                        mainHistory.replace(url)
                        break
                }
            }
        })

        class View extends Component<RouteComponentProps> {

            static childContextTypes = {
                view: PropTypes.string.isRequired,
                urlSplitPosition: PropTypes.number
            }

            getChildContext() {
                return {
                    view: name,
                    urlSplitPosition: views[name].urlSplitPosition,
                }
            }

            render() {
                return this.props.children
            }
        }

        const router = createElement(Router, { history: viewHistory },
            createElement(withRouter(View), undefined, this.mainModule)
        )

        return router

    }

    static showView(name: string, url: string) {
        const paramKey = `(${name})`
        const params = new URLSearchParams(mainHistory.location.search)
        params.set(paramKey, url)
        mainHistory.push(`${mainHistory.location.pathname}?${params.toString()}`)
    }

    static closeView(name: string, redirectUrl?: string) {
        const paramKey = `(${name})`
        const params = new URLSearchParams(mainHistory.location.search)
        params.delete(paramKey)

        const url = redirectUrl ? redirectUrl : `${mainHistory.location.pathname}?${params.toString()}`
        mainHistory.push(url)
    }

    static anyOpenView(name?: string) {
        const decodedUrl = decodeURIComponent(location.search)
        return name ? decodedUrl.indexOf(`(${name})`) > 0 : /\((\d|\w)*\)/.test(decodedUrl)
    }

    static renderTemplate<R extends TemplateRenderer>(bundleName: string, name: string, renderer: R) {
        return renderTemplate(context, bundleName, name, renderer)
    }

    static addErrorListener(listener: (error: any) => void): () => void {
        function removeListener(listener: (error: any) => void) {
            const pos = errorListener.indexOf(x => x == listener)
            if (pos != -1) {
                errorListener.splice(pos, 1)
            }
        }
        if (!errorListener.some(x => x == listener)) {
            errorListener.push(listener)
        }
        return removeListener.bind(undefined, listener)
    }

    static getHistory(): History {
        return mainHistory
    }

    /**
     * returns the global parameters of the catalog
     * if bundle parameters is pased it returns specific bundle parameters
     * @param bundle optional -- name of specific bundle (example: 'parts')
     */
    static getParams<T = any>(bundle?: string): T {
        let params = {}
        if (bundle)
            params = context.config.bundles?.[bundle]?.params ?? {}
        else
            params = context.config.params ?? {}
        return params as T
    }

}

export type ViewListener = (action: "OPEN" | "CLOSE") => void
