import { CatalogContextType } from "@tm/models"
import { EventStorage } from "../event-storage"
import { TmaEvent, BaseTrackingModel } from "../../models/models"
import { VehicleSelectionLog } from "./models"
import { DataPublisher } from "../../publishing-service/models"

const CatalogContextCollection: { [key: string]: CatalogContextType } = {
    "vehicle.list": CatalogContextType.None, // TODO: set the correct context type
}

export class VehicleSelection extends DataPublisher<VehicleSelectionLog> implements TmaEvent {
    key: string

    private data: VehicleSelectionLog

    storageId?: string

    /**
     * FakeTmaSender
     */
    get requestBody() {
        let data = this.storage.get()
        if (data && this.storageId) {
            // @ts-ignore
            data = data[this.storageId]
        }
        return data
    }

    set requestBody(data: VehicleSelectionLog | undefined) {
        let tData: any = data
        if (this.storageId) {
            tData = {
                ...tData,
                [this.storageId]: data,
            }
        }

        this.storage.set(tData)
    }

    handleWorkTaskChange(workTaskId?: string, previousWorkTaskId?: string) {
        this.storageId = workTaskId
    }

    constructor() {
        const key = "VEHICLE_SELECTION"
        super(new EventStorage(key))
        this.key = key

        this.data = {
            catalogContext: CatalogContextType.None,
        }

        this.handleWorkTaskChange = this.handleWorkTaskChange.bind(this)
    }

    handle(data: BaseTrackingModel<any>) {
        if (this.key != data.tmaEvent) {
            return
        }

        const { action, payload, bundle, component } = data

        switch (action) {
            case "setIdentification": {
                this.data.dataSourceId = payload.dataSourceId
                this.data.searchTerm = payload.searchTerm
                this.data.catalogContext = payload.contextType
                break
            }

            case "setContextType": {
                this.data.catalogContext = payload.type
            }

            case "setOrigin": {
                this.data.catalogContext = payload.type
            }

            case "selectConstructionYearFilter": {
                if (!payload) {
                    return
                }

                this.data.selectedConstructionYearFilter = payload.year // can be undefined
                break
            }

            case "handleApply": {
                if (!payload || !payload.dataSourceId) {
                    return
                }

                this.data.dataSourceId = payload.dataSourceId

                this.setCatalogContextType(bundle, component)
                break
            }

            case "search": {
                this.data.searchTerm = payload?.searchTerm
                break
            }
        }

        this.requestBody = this.data
    }

    private setCatalogContextType(bundle: string, component: string) {
        const catalogContext = CatalogContextCollection[`${bundle}.${component}`]

        if (catalogContext) {
            this.data.catalogContext = catalogContext
        }
    }
}

export default new VehicleSelection()
