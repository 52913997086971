import { AnalyticsEventTriggerType, RepairTimeProvider, UserInteractionLog } from "@tm/models"
import { TmaEventTracking } from "../.."
import { uniqueId } from "../../.."
import { getCatalogContext } from "../../helper/context"

export const AddRepairTimeListEventHelper = {
    AddRepairTimeList: {
        GetUserInteractionLog: (repairtimeProvider: RepairTimeProvider): UserInteractionLog | undefined => {
            const articleListFilteredRequest = TmaEventTracking.getRequestData("ARTICLE_LIST_FILTERED")

            if (articleListFilteredRequest?.searchStep) {
                const log: UserInteractionLog = {
                    eventId: uniqueId(),
                    id: articleListFilteredRequest.searchStep!.processId,
                    // stepNumber: articleListFilteredRequest.searchStep!.number,
                    // fromPageNumber: articleListFilteredRequest.articleList?.paging.pageIndex,
                    // fromPositionNumber: positionNumber,
                    catalogContext: getCatalogContext(),
                    trigger: mapProviderToTrigger(repairtimeProvider),
                }
                return log
            }

            return undefined
        },
    },
}

function mapProviderToTrigger(providerId: RepairTimeProvider) {
    switch (providerId) {
        case RepairTimeProvider.AwDocCar: {
            return AnalyticsEventTriggerType.AwDocRepairTimes
        }
        case RepairTimeProvider.Autodata: {
            return AnalyticsEventTriggerType.AutodataRepairTimes
        }
        case RepairTimeProvider.HaynesProCar: {
            return AnalyticsEventTriggerType.HaynesProRepairTimes
        }
        case RepairTimeProvider.TecRmiCar: {
            return AnalyticsEventTriggerType.TecRmiRepairTimes
        }
        default: {
            return AnalyticsEventTriggerType.None
        }
    }
}
