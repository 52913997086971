import { ListRequest, Version } from ".."
import { ArticleReferenceType, FittingPosition, PriceType, SearchFilters } from "../enumerations"
import { Override } from "../types"

export type Article = {
    /**
     * The property `id` returned by service is the internal article number.
     * In the frontend the `id` property is a genereated UUID and the original `id` is stored in `internalId`.
     */
    id: string
    /**
     * Added by frontend.
     * The internal article number originally returned by the service in the `id` property.
     */
    internalId: number
    supplierArticleNo: string
    traderArticleNo: string
    matchingArticleNo: string
    description: string
    traderDescription: string
    additionalDescription: string
    accessoryDescription: string
    information: Array<ArticleInfo>
    attributes: Array<ArticleAttributes>
    references: Array<ArticleReference>
    prices?: Array<ArticlePrice>
    options: ArticleOptions
    vehicleLinkageId: number
    vehicleInformation: Array<ArticleInfo>
    additionalReferenceArticleInformation: AdditionalReferenceArticleInformation
    supplier: DataSupplier
    productGroup: ProductGroup
    status: Array<ArticleStatus>
    packaging?: Packaging
    quantity: number
    /**
     * This is a suggestion based on TMA data. After loading the article data `quantity` will already be set to the suggested quantity if enabled.
     */
    suggestedQuantity?: number
    /**
     * Added by frontend.
     * The quantity initially transferred by the article service is copied to this field.
     * The value shouldn't be changed afterwards, so that it e.g. can be used to reset the quantity field after the article has been added to the basket.
     */
    initialQuantity: number
    fittingSide?: FittingPosition
    isReplacementArticle: boolean
    isTraderAddition: boolean
    articleModificationState: EArticleModificationState
    referencedArticleModification?: ReferencedArticleModification
    isMandatoryCertificationRequired: boolean
    isAccessory: boolean
    isPartsListAvailable: boolean
    isAccessoryListAvailable: boolean
    thumbnail: string
    existImage: boolean
    existPdf: boolean
    priority: number
    lastUpdate?: Date
    imageCoordinates: string
    requestErpInfo: boolean
    showAddToBasket: boolean
    isExactMatch: boolean
    showDbPrices: boolean
    vehicleDescriptions?: Array<string>
    documents?: ArticleDocument[]
    articleListPosition?: number
    availableBonusSystems?: Array<BonusSystem>
    useSpecialProcurement?: boolean
    sswRimInformation?: string
    montageauflagen?: string
}

export type ArticleImage = {
    description: string;
    name: string;
    imageUrl: string;
    thumbnailUrl: string;
};

export type ArticleDocument = {
    description: string
    documentType: number
    url: string
}

export type ArticleInfoGroup = {
    type: number
    name: string
    member: Array<ArticleInfo>
    hasName: boolean
    hasMember: boolean
}

export type ArticleInfo = {
    description: string
    content: string
    mediaType: EMediaType
    mediaTypeDescription: string
    contentTypeId: number
    contentTypeDescription: string
    tecdocInfoType: ETecdocInfoType
    options: ArticleOptions
    sortId?: number
    priority: number
}

export enum EMediaType {
    None = 0,
    Video = 1,
    Audio = 2,
    Pdf = 3,
    Website = 4,
    YoutubeChannel = 5,
    Text = 6,
    HeadTextInfo = 7,
}

export enum ETecdocInfoType {
    None = 0,
    TechnicalInfo = 1,
    GeneralInfo = 2,
    AssemblyInfo,
    RepairTime,
    SafetyInfo,
    MessureInfo,
    Lieferumfang,
    Sonderzubehör,
    Kurzinformation
}

export type ArticleAttributes = {
    topAttributes: Array<ArticleAttribute>
    articleAttributes: Array<ArticleAttribute>
    vehicleAttributes: Array<ArticleAttribute>
    partsListAttributes: Array<ArticleAttribute>
}

export type ArticleAttribute = {
    id: number
    description: string
    abbreviation: string
    text: string
    key: string
    value: string
    unit: string
    modificationState: ModificationState
    highlight: boolean
    sortNo: number
    isBlockSeperator: boolean
    alternatives?: Array<ArticleAttribute>
    isOptimized: boolean
    action?: AttributeAction
    isDuplicatedAttribute?: boolean // Added to hide labels of duplicated attributes, e.g. multiple engine codes
    productGroupId?: number;
}

export enum EArticleModificationState {
    NotModified = 0,
    ArticleAdded = 1,               // Der Artikel wurde vom Teilehändler hinzugefügt.
    ArticleDisabled = 2,            // Der Artikel wurde vom Teilehändler gesperrt.
    VehicleLinkageAdded = 3,        // Fahrzeugzuordnung wurde durch Teilehändler hinzugefügt.
    VehicleLinkageDisabled = 4      // Artikel nicht verwenden, Passgenauigkeit nicht garantiert!
}

export enum ModificationState {
    NotModified = 0,
    Added = 1,
    Removed = 2,
}

export enum AttributeAction {
    Filter = 0,
    DirectSearch = 1,
    OpenAccessoryList = 2,
    OpenPartsList = 3,
}

export type ArticleReference = {
    information?: string
    referenceNo: string
    referenceType: ArticleReferenceType
    description: string
    value: string
    manufacturerId?: number
    manufacturer?: string
    productGroup?: ProductGroup
    modificationState: ModificationState
}

export type ArticlePrice = {
    description: string
    priceType: PriceType
    traderDescription?: string
    traderPriceType?: string
    value: number
    currencyCode: string
    currencySymbol?: string
    countryCode: string
    quantityUnitId: number
    quantityUnit: string
    scaleQuantity: number
    priceUnitId: number
    priceUnit: string
    validFrom: Date
    validTo?: Date
    discount: number
    discountGroupId: number
    deliveryStatusId: number
    deliveryStatus: number
    isTraderPrice: number
}

export type ArticleOptions = {
    showInArticleList: boolean
    blocked: boolean
    ignoreInSearch: boolean
    highlight: boolean
    modified: boolean
    addedByWholesaler: boolean
    articleInformationEditedByWholesaler: boolean
}

export type AdditionalReferenceArticleInformation = {
    headInformation: Array<string>
    links: Array<ArticleInfoGroup>
    textInformation: Array<ArticleInfoGroup>
}

export type DataSupplier = {
    id: number
    name: string
    abbreviation: string
    isTopPriority: boolean
    manufacturerId?: number
}

export type ProductGroup = {
    id: number
    name: string
    thumbnail: string
    sortId: number
}

export type ArticleStatus = {
    id: number
    description: string
    validFrom?: Date
    country?: string
    countryCode?: string
}

export type Packaging = {
    batchSize?: number
    batchSize2?: number
    packagingUnit: number
    quantityPerUnit: number
    isSelfServicePackaging: boolean
}

export type Item = {
    id: string
    version: Version
    parentItem?: Item   //used mainly in basket
}

export type GetArticlesRequest = ListRequest & {
    query?: string
    searchFilter?: SearchFilters
    supplierArticleNos?: Array<string>
    productGroupIds?: Array<number>
    supplierIds?: Array<number>
    extendedAssortment?: boolean
    attributeFilter?: string
    oeReferences?: Array<string>
    modelId?: number
    vehicleType?: number
    fittingSideFilter?: FittingPosition
    constructionYearFilter?: number
    treeId?: number
    nodeId?: number
    useErpTradeReference?: boolean
    useEqualArticlesFilter?: boolean
    oeManufacturerIds?: Array<number>
    oeInformationWithoutCar?: boolean
}

export type GetArticlesResponse = {
    articles: Array<Article> // this may by wrong
    pageIndex: number;
    pageSize: number;
    articleListCount?: number;
}

export type GetUniArticlesResponse = {
    uniArticles: Array<Article> // this may by wrong
    pageIndex: number;
    pageSize: number;
    articleListCount?: number;
}

export type ArticleIdentifier = {
    supplierId: number
    supplierArticleNo: string
    productGroupId: number
    tecdocTypeNo?: number
}

export type SupplierArticle = {
    supplierId: number
    supplierArticleNo: string
}

export type BonusSystem = {
    id: number
    name: string
    imageUrl: string
    value?: number
}

export type BonusSystemWithoutPoints = Omit<BonusSystem, "value">

export type BonusSystemWithPoints =  Override<BonusSystem, { value: number }>

export type ReferencedArticleModification = {
    referencedArticleInfos?: Array<ArticleSearchHitInfo>
    isAddedReferencedArticle?: boolean
    isRemovedReferencedArticle?: boolean
}

export type ArticleSearchHitInfo = {
    searchFilter: SearchFilters
    referenceNo: string
    manufacturerName?: string
    oeReferenceInfo?: string
}
