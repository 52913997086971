import { getWorkTaskId, cleanContextUrl } from "../helper/context"

/***
 * kennt nur stores welche über einen identifier identifiziert werden (zb /parts/direct/list)
 * tmaStoreKey ist die id für den local/session/storage
 */
export class EventStorage<TStoredData = any> {
    tmaStoreKey = "tma"
    identifier: string
    contextUrl: string
    /**
     * represents request content
     */
    body?: any

    private isModified: { [prcoessId: string]: boolean }

    constructor(identifier: string) {
        const tmaStore = sessionStorage.getItem(this.tmaStoreKey)

        this.identifier = identifier
        this.contextUrl = window.document.location.pathname
        this.isModified = {}

        if (!tmaStore) {
            sessionStorage.setItem(this.tmaStoreKey, "{}")
        }
    }

    removeModifiedState(processId: string) {
        if (this.isModified[this.identifier + processId] !== undefined) {
            delete this.isModified[this.identifier + processId]
            this.syncModifiedStorage()
        }
    }

    syncModifiedStorage() {
        const baseStorage = this.getTmaStore()
        const modified = {...(baseStorage["modified"] || {}), ...this.isModified}
        sessionStorage.setItem(this.tmaStoreKey, JSON.stringify(modified))
    }

    setModified = (processId: string, modified: boolean) => {
        if (this.isModified[this.identifier + processId]) { return }
        const baseStorage = this.getTmaStore()
        this.isModified[this.identifier + processId] = modified
        baseStorage["modified"] = baseStorage["modified"] || {}
        baseStorage["modified"][this.identifier + processId] = this.isModified[this.identifier + processId]
        sessionStorage.setItem(this.tmaStoreKey, JSON.stringify(baseStorage))
    }

    getModified = (processId: string) => {
        const baseStorage = this.getTmaStore()
        if (baseStorage["modified"]) {
            this.isModified[this.identifier + processId] = baseStorage["modified"][this.identifier + processId]
        }
        return this.isModified[this.identifier+processId]
    }

    setModifiedToFalse = (processId: string) => {
        this.isModified[this.identifier + processId] = false
        this.setModified(processId, false)
    }

    set = (content: TStoredData) => {
        this.body = content
        // store inside tma namespace, aswell as in the property body
        this.storeContent(content)
    }

    get = (): TStoredData | undefined => {

        if (this.body?.context &&
            (
                this.body.context.identifier !== this.identifier ||
                this.body.context.contextId !== this.getContextId()
            )
        ) {
            this.body = undefined
        }

        if (!this.body) {
            // looking up if body already exists
            const storedBody = this.getContent()
            if (storedBody)
            this.body = storedBody
        }

        return this.body || {}
    }

    loadContent = (context: StoreContext) => {
        return this.getContent(context)
    }

    delete = (context: StoreContext) => {
        const { identifier, contextId } = context
        const tmaStore = this.getTmaStore()

        if (!tmaStore[identifier][contextId]) {
            return
        }

        delete tmaStore[identifier][contextId]
        const stringifiedStore = JSON.stringify(tmaStore)
        sessionStorage.setItem(this.tmaStoreKey, stringifiedStore)
    }

    // store content depending on the current worktask (identifier) and the catalog context (contextid)
    private storeContent = (content: any) => {
        if (Object.keys(content).length === 0) {
            return
        }

        if (!content.context) {
            content.context = this.getContext()
        }
        const contextId = content.context?.contextId || this.getContextId()
        const identifier = content.context?.identifier || this.identifier

        this.checkForModifications(content)

        const tmaStore = this.getTmaStore()
        tmaStore[identifier] = tmaStore[identifier] || { [contextId]: {} }


        tmaStore[identifier] = {
            ...tmaStore[identifier],
            [contextId] : {
                ...tmaStore[identifier][contextId],
                ...content,
                context: {
                    contextId: contextId,
                    identifier: identifier
                }
            }
        }

        this.writeToSessionStorage(tmaStore)

    }

    private writeToSessionStorage = (newTmaStore: any) => {
        const stringifiedStore = JSON.stringify(newTmaStore)
        sessionStorage.setItem(this.tmaStoreKey, stringifiedStore)
    }

    /***
     * returns a context object
     * @return { context: string, identifier: string }
     */
    public getContext() {
        return {
            contextId: this.getContextId(),
            identifier: this.identifier
        }
    }

    /**
     * need to get a clear id to identify the concrete context (articlelist in modal, articlelist, ...)
     * @returns id of the current context
     */
    getContextId() {
        const wkId = getWorkTaskId()
        if (!wkId) { return "startpage" }
        const contextId = document.location.pathname + document.location.search
        return cleanContextUrl(contextId)// ?.hashCode()
    }
    /**
     * Get the content depending on the worktaskid
     * if we start from dashboard the worktaskid will be set as contextId and the event will search for a full pathname like '/0lZjPL1rmOuC9M81CTh2P5/parts/universal/list/uninode'

     * @returns returns the stored content
     */
    private getContent = (storeContext?: StoreContext) => {
        const tmaStore = this.getTmaStore()
        const context = storeContext || {
            identifier: this.identifier,
            contextId: this.getContextId()
        }

        let storedContent = tmaStore[context.identifier] ?? {}
        let parsedContent = storedContent[context.contextId] || this.getPreInitializedContent(context, tmaStore) || {}
        return parsedContent
    }

    private getTmaStore = () => {
        const raw = sessionStorage.getItem(this.tmaStoreKey) || "{}"
        return JSON.parse(raw)
    }

    private checkForModifications = (currentObject: any) => {

        const processId = currentObject?.searchStep?.processId
        if (!processId || processId.toString() === "-1") {return false}
        if (this.getModified(processId)) { return true }

        const contextId = currentObject.context?.contextId || this.getContextId()
        const identifier = currentObject.context?.identifier || this.identifier

        const storedObject = this.getTmaStore()[identifier]?.[contextId]


        const stored = JSON.stringify( storedObject || {})
        const current = JSON.stringify( currentObject || {})

        this.setModified(processId, stored !== current)

        if (sessionStorage.getItem("storeIdent") === "*" || identifier === (sessionStorage.getItem("storeIdent") || "/articles/articleListFiltered")) {
            // console.log("stored modified value", this.isModified[processId])
            // console.log("current modified value", this.getModified(processId))
            // console.log("%csetModified","color:blue", this.identifier, getWorkTaskId())
        }
    }

    private swapToQualifiedContextId = (context: StoreContext, initialContextId: string, initialContent: any, store: { [key: string]: { [key: string]: any } }) => {
        if (initialContent && context.contextId.length > initialContextId.length && context.contextId.startsWith(initialContextId)) {
            delete store[context.identifier][initialContextId]
            store[context.identifier][context.contextId] = initialContent
            this.writeToSessionStorage(store)
        }
    }

    private getPreInitializedContent = (context: StoreContext, store: { [key: string]: {[key:string]: any}}) => {
        const initialContextId = "/" + getWorkTaskId()
        const storedContext = store[context.identifier]
        const initialContent = storedContext ? storedContext[initialContextId] : undefined

        this.swapToQualifiedContextId(context, initialContextId, initialContent, store)

        return initialContent
    }
}


export type StoreContext = { identifier: string, contextId: string }
