import { CatalogContextType } from "@tm/models"

export const ContextTypes: { paths: string[]; context: CatalogContextType }[] = [
    {
        context: CatalogContextType.Wheels,
        paths: ["/wheels"],
    },
    {
        context: CatalogContextType.TmTyreSearch,
        paths: ["/tyres"],
    },
    {
        context: CatalogContextType.TyresTecRmi,
        paths: ["/tecrmi-tyres"],
    },
    {
        context: CatalogContextType.UniversalPartList,
        paths: ["/parts/universal/list"],
    },
    {
        context: CatalogContextType.CatalogPartList,
        paths: ["/parts/direct/list"],
    },
    {
        context: CatalogContextType.GraphicalVehicleList,
        paths: ["/vehicles/details", "/vehicles/select"],
    },
    {
        context: CatalogContextType.PartDetails,
        paths: ["modal/part-details"],
    },
    {
        context: CatalogContextType.CatalogPartList,
        paths: ["/parts/vehicles/list"],
    },
    {
        context: CatalogContextType.DirectBuyShoppingCart,
        paths: ["/basket"],
    },
    {
        context: CatalogContextType.CostEstimation,
        paths: ["/cost-estimation"],
    },
    {
        context: CatalogContextType.Offers,
        paths: ["/offers"],
    },
    {
        context: CatalogContextType.TechnicalDataHaynes,
        paths: ["/td/haynespro"],
    },
    {
        context: CatalogContextType.TechnicalDataTecRmi,
        paths: ["/td/tecrmi"],
    },
    {
        context: CatalogContextType.TechnicalDataAutodata,
        paths: ["/td/autodata"],
    },
    {
        context: CatalogContextType.GpiDat,
        paths: ["/gpi/dat"],
    },
    {
        context: CatalogContextType.GpiEurotax,
        paths: ["/gpi/eurotax", "/eurotax"],
    },
    {
        context: CatalogContextType.GpiTemot,
        paths: ["/gpi/temot"],
    },
    {
        context: CatalogContextType.GpiTecRmi,
        paths: ["/gpi/tecrmi"],
    },
    {
        context: CatalogContextType.EurotaxRepairEstimation,
        paths: ["/damage-calculation/eurotax"],
    },
    {
        context: CatalogContextType.DatRepairEstimation,
        paths: ["/damage-calculation/dat"],
    },
    {
        context: CatalogContextType.GlassRepairEstimationDat,
        paths: ["/damage-calculation/glass"],
    },
    {
        context: CatalogContextType.AsWegasRepairEstimation,
        paths: ["/damage-calculation/aswegas"],
    },
    {
        context: CatalogContextType.TruckVin,
        paths: ["/truck/vin"],
    },
    {
        context: CatalogContextType.MyTruck,
        paths: ["/truck/my"],
    },
    {
        context: CatalogContextType.MyTruckPlus,
        paths: ["/truck/plus"],
    },
    {
        context: CatalogContextType.TruckGarage,
        paths: ["/truck/garage"],
    },
    {
        context: CatalogContextType.FastCalculation,
        paths: ["/fast-calculator-next"],
    },
    {
        context: CatalogContextType.FastCalculation,
        paths: ["/fast-calculator"], // FC v1
    },
    {
        context: CatalogContextType.FastCheck,
        paths: ["/fastcheck"],
    },
    {
        context: CatalogContextType.FastService,
        paths: ["/fast-service"],
    },
    {
        context: CatalogContextType.FastDtc,
        paths: ["/fastdtc"],
    },
    {
        context: CatalogContextType.Eds,
        paths: ["/eds"],
    }
]
