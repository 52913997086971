export * from "./authority"
export * from "./cis"
export * from "./customer"
export * from "./erp"
export * from "./memo-tool"
export * from "./order-options"
export * from "./parts"
export * from "./portal"
export * from "./state"
export * from "./urlResolver"
export * from "./vehicle"
export * from "./vehicle-records"
export * from "./worktask"


export enum RegisteredModels {
    Worktask,
    Customer,
    Vehicle,
    ERP,
    ViewState,
    /** @deprecated Use `Container.getInstance(RegisteredModels.ERP).action("getErpInfoDetails")(...)` instead. */
    ERP_Details,
    ERP_Teccom,
    Logging,
    CIS_Customer,
    Vehicle_ModelDetails,
    Articles,
    RepairTimes_MainServices,
    RepairTimes_HasRepairTimes,
    Basket_BasketInfo,
    Basket_HasRepairTimes,
    Basket_HasRepairTimesForProductGroup,
    Worktask_BasketActivityDone,
    Compilations_List,
    Vehicle_KbaNumbers,
    Articles_ByWholesalerArticleNumber,
    Articles_ByArticleNumbersWithOptionalVehicle,
    Articles_ByWholesalerArticleNumbers,
    ERP_OrderOptions,
    RepairTimes_ShowByIds,
    Basket_Order_ShowItem,
    Worktask_CostEstimationDetails,
    Basket_CostEstimation_ShowTotalNumbersByWorkTask,
    RepairTimes_ShowManufacturerDivision,
    ERP_GetPromotedItems,
    Vouchers_ShowCostEstimationPdf,
    KeyValueStore,
    UrlResolver,
    VehicleRecords,
    Vouchers_ShowSupplierArticleByVehicle,
    CIS_ShowTotalNumbers,
    Notifications_ShowAlerts,
    Vehicle_ShowOptions,
    RepairTimes_TechnicalData_GetProductGroupTopicIds,
    ERP_ShowDefaultWarehouse,
    Authority,
    Articles_ByArticleNumbersWithOptionalVehicleBuffered,
    Basket_OeBasketInfo,
    ERP_GetCompleteDeliveryTour,
    ERP_GetBonusInformation,
    ERP_GetAdvertisementCategories,
    ERP_GetAdvertisement,
    Vouchers_CreateCostEstimation,
    Articles_BySupplierArticleNumbers,
    ImportExport,
    Basket_WholesalerBasketInfo,
    Vouchers_ShowAllOrders,
    CIS_ShowAllOrders,
    CIS_ShowOpenOrders,
    Vehicle_ManufacturerDetails,
    Vehicle_ModelSeriesDetails,
    ERP_GetNotification,
    ERP_GetErpConfig,
    Articles_GetMultiWholesalerArticleNosByTraderId,
    Vehicle_DataAvailabilities,
    Vouchers_ShowOrderPdf,
    CIS_ShowOrdersByArticle,
    CIS_ShowCisOptions,
    Print_VehicleDatInfo,
    Article_Notes,
    WorkTask_Notes,
    Articles_GetArticleImageByInternalArticleId,
    ERP_DefaultOrderOptions,
    ERP_OrderStatus,
    MemoTool,
    CIS,             //This should be improved, all CIS registerded models should be defined in as action withtin the CIS registered model
    ERP_GlobalOrderOptions,
    RepairShop_AddCalculatedCosts
}
