import { Box, Stack } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ErpPrice } from "@tm/models"

type Props = {
    price: ErpPrice
}

export function TooltipPrice({ price }: Props) {
    const { currency, translateText } = useLocalization()

    return (
        <Stack spacing={1}>
            {!!price.description && <Box>{price.description}</Box>}
            {!price.taxIncluded && price.valueTaxIncluded && (
                <Box>
                    {translateText(12851)}:&nbsp;
                    {currency(price.valueTaxIncluded, price.currencySymbol || "")}
                </Box>
            )}
            {!!price.rebate && (
                <Box>
                    {translateText(54)}:&nbsp;
                    {price.rebate > 1 ? price.rebate : (price.rebate * 100).toFixed(0)}%
                </Box>
            )}
        </Stack>
    )
}
