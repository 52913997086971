export type UserInteractionLog = {
    eventId: string // not used in backend
    id?: string

    catalogContext: CatalogContextType
    trigger: AnalyticsEventTriggerType
    feature?: ECatalogFeature

    stepNumber?: number
    fromPageNumber?: number
    fromPositionNumber?: number

    vehicleSearchTerm?: string // not used in backend
    vehicleDataSourceId?: number // not used in backend

    foundByQuery?: string
}

export enum AnalyticsEventTriggerType {
    None = 0,
    CatalogPartDirectSearch = 1,
    ProductGroupKeywordSearch = 2,
    FastClickV1 = 3,
    FastClickV2 = 4,
    UniversalPartKeywordSearch = 5,
    AwDocRepairTimes = 7, //REPTIMES_AWDOC
    AutodataRepairTimes = 8, //REPTIMES_AUTODATA
    TecRmiRepairTimes = 9, //REPTIMES_AUDACON
    HaynesServiceData = 10, //SERVICE_HAYNES
    AutodataServiceData = 11, //SERVICE_AUTODATA
    TecRmiServiceData = 12, //SERVICE_AUDACON
    HaynesTechnicalData = 13, //TECH_HAYNES
    AutodataTechnicalData = 14, //TECH_AUTODATA
    TecRmiTechnicalData = 15, //TECH_AUDACON
    EurotaxGpi = 16, //GPI_EUROTAX
    DatGpi = 17, //GPI_DAT
    TemotGpi = 18, //GPI_TEMOT
    DatRepairEstimation = 19, //REPESTIMATE_DAT
    EurotaxRepairEstimation = 20, //REPESTIMATE_EUROTAX
    HaynesProRepairTimes = 21, //REPTIMES_HAYNESPRO
    PartsIndicator = 22,
    CustomArticle = 23,
    FastDtc = 24,
    FileImportBasket = 25
}

export type VehicleSelectionLog = {
    catalogContext: CatalogContextType
    selectedConstructionYearFilter?: number

   /**
    * VEHICLE_IDENTIFICATIONTYPEID
    * The value of the id can be taken from the MDM list for available types of vehicle identification
    * (see Visual Studio TeamExplorer - Project MDM - Documents - Development - Kundenverwaltung.xlsx - System-AddOn).
    * IdentificationTypeId defines type of the identification process like identification by vehicle tree search,
    * plateId, motor code, TecDocTypeId, Gerausiv webservice, etc.
    */
    dataSourceId: number

   /**
    * VEHICLE_IDENTIFICATIONKEY
    * Search keyword. In case of a plateId search the plateId.
    */
    searchTerm: string
}

export enum CatalogContextType {
    None = 0,

    CatalogPartList = 1, //ARTICLE_LIST
    PartDetails = 2, //ARTICLE_DETAILS
    PartList = 3, //ARTICLE_DETAILS_PARTSLIST
    AccessoryList = 4, //AERTICLE_DETAILS_ACCESSORYLIST
    PartComparison = 5, //ARTICLE_COMPARISON
    UniversalPartList = 6, //PREMIUMUNIPARTSSEARCH

    WorkTaskOrderBasket = 7, //SHOPPING_CART
    OrderBasket = 8, //SHOPPING_CART
    OrderOptions = 9, //SHOPPING_CART
    CostEstimation = 10, //SHOPPING_CART
    FastCalculation = 11,
    DirectBuy = 12, //DIRECTBUY

    Dashbord = 13, //START_PAGE
    StartPage = 14, //START_PAGE

    EurotaxRepairEstimation = 15, //REPESTIMATE_EUROTAX

    HiddenLogin = 16,

    RepairTimesAwDoc = 17,
    RepairTimesAutodata = 18,

    /// <summary>
    /// TecRMI is the new name of Audacon.
    /// </summary>
    RepairTimesTecRmi = 19,
    ServiceDataHaynes = 20,
    ServiceDataAutodata = 21,

    /// <summary>
    /// TecRMI is the new name of Audacon.
    /// </summary>
    ServiceDataTecRmi = 22,
    TechnicalDataHaynes = 23,
    TechnicalDataAutodata = 24,

    /// <summary>
    /// TecRMI is the new name of Audacon.
    /// </summary>
    TechnicalDataTecRmi = 25,
    GpiEurotax = 26,
    GpiDat = 27,
    GpiTemot = 28,
    DatRepairEstimation = 29,
    DirectBuyShoppingCart = 35,
    TruckVin = 39,
    TruckGarage = 40,
    GraphicalVehicleList = 44,
    AsWegasRepairEstimation = 62,
    Offers = 63,
    GpiTecRmi = 65,
    TyresTecRmi = 66,
    MyTruck = 91,
    RepairTimesHaynes = 92,
    FastCheck = 103,
    DatGpiRapidCalculation = 104,
    Wheels = 105,
    PartCompilations = 109,
    PartsIndicator = 110,
    TmTyreSearch = 111,
    MyTruckPlus = 113,
    GlassRepairEstimationDat = 114,
    FastService = 115,
    FastDtc = 116,
    Eds = 117
}

export enum ECatalogFeature {
    None = 0,
    CatalogPartSearch = 1,
    UniversalPartSearch = 2
}
