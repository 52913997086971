import { AnalyticsEventTriggerType, ECatalogFeature } from "@tm/models"
import { uniqueId } from "../../../helpers/id"
import { getCatalogContext } from "../../helper/context"
import { ArticleListFilteredRequest } from "./models"
import { StoreContext } from "../event-storage"

export type CategoryType = "vehicleParts" | "universalParts" | "directSearch" | "dtcSearch"

export function getSearchQueryFromUrl() {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get("query")
}

export function getCategoryTypeFromUrl(url: string): CategoryType {
    // TODO: this has to be rethinked
    // TODO: this should not be dublicated 3 times

    if (url.includes("/parts/vehicles")) {
        return "vehicleParts"
    }

    if (url.includes("/parts/universal")) {
        return "universalParts"
    }

    if (url.includes("/fastdtc")) {
        return "dtcSearch"
    }

    return "directSearch"
}

export function getPageIndexMax(maxArticleCount: number | undefined, pageSize: number | undefined): number | undefined {
    if (!pageSize || !maxArticleCount) {
        return
    }

    const pageIndexMax = maxArticleCount / pageSize

    if (isNaN(pageIndexMax)) {
        return
    }

    return Math.ceil(pageIndexMax)
}


export const createEvent = {
    initial: (vehicleId?: string): ArticleListFilteredRequest => {
        return {
            searchStep: {
                number: 1, // can be delete later on, but set searchStep.number to 1 when inside reset()
                processId: uniqueId(),
            },
            origin: {
                context: getCatalogContext(),
                trigger: AnalyticsEventTriggerType.None,
                feature: ECatalogFeature.None
            },
            vehicleId: vehicleId,
            productGroupsPerSupplier: {},
            eventStatus: "initial"
        }
    }
}

/**
 *
 * @param a ArticleListFilteredRequest
 * @param b ArticleListFilteredRequest
 * @returns true if it's equal, false if it's unequal
 */
export function compareEvents(a: ArticleListFilteredRequest, b: ArticleListFilteredRequest) {
    if (a && b) {
        const storedContext = a.context
        const tempContext = b.context
        if (storedContext?.contextId === tempContext?.contextId &&
            storedContext?.identifier === tempContext?.identifier &&
            a.searchStep?.processId === b.searchStep?.processId &&
            a.searchStep?.number === b.searchStep?.number) {
            return true
        }
    }
    return false
}

/**
 *
 * @param a StoreContext
 * @param b StoreContext
 * @returns true if it's equal, false if it's unequal
 */
export function compareContext(a: StoreContext, b: StoreContext) {
    //
    return ((a || b) && a.contextId === b.contextId && a.identifier === b.identifier )
}

/**
 *
 * @param contextId current context id
 * @returns true if its a modal
 */
export function isModal(contextId: string) {
    return contextId.includes("modal")
}

