import { BundlesWithReplaceButtonMicro } from "@tm/models"

/**
 * please add a description if you know this function
 * @param value
 * @returns
 */
export function isBundleWithReplaceButtonMicro(value: string): value is BundlesWithReplaceButtonMicro {
    console.warn("depcrated, import from utils")
    return value == "fast-calculator-next" || value == "dmg-dat"
}
