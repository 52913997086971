export enum QuerySearchType {
    Undefined = 0,
    Direct = 1,
    Synonym = 2,
    ErrorCode = 3,
    ProductGroup = 4
}

export interface QuerySearch {
    /**
     * @property [optional] The query string, also known as Str, Suchstring
     */
    query: string
    searchType: QuerySearchType
    autocompleteEntryExists: boolean
}
