import { AnalyticsEventTriggerType, CatalogContextType, CategoryType, channel, ECatalogFeature, GetArticlesRequest, GetArticlesResponse, Article, Vehicle } from "@tm/models"
import cloneDeep from "clone-deep"
import { uniqueId } from "../../.."
import { Dictionary } from "../../../types"
import { getCatalogContext } from "../../helper/context"
import { Filter, TmaEvent, TrackingModel } from "../../models/models"
import { ArticleListViewOptionsType, SearchFilterType } from "../../models/requests"
import { QuerySearchType } from "../base-event-models"
import { TmaSender } from "../tma-sender"
import { ArticleListViewOptions } from "./event-helper"
import { EventSubmitDelay } from "./event-submit-delay"
import { compareContext, createEvent, getCategoryTypeFromUrl, getPageIndexMax, getSearchQueryFromUrl, isModal } from "./helper"
import { AnalyticsData, ArticleListFilteredRequest, ArticleListInfo, ProductGroupDataSupplier, SearchFilter } from "./models"
import { StoreContext } from "../event-storage"

export class ArticleListFilteredEvent extends TmaSender<ArticleListFilteredRequest> implements TmaEvent {
    key = "ARTICLE_LIST_FILTERED"

    static serviceEndpoint = "/articles/articleListFiltered"

    currentVehicleId?: string

    searchFilters: Array<SearchFilter> = []

    autocompleteResults: Array<string> = []

    additionalAnalyticsData: AnalyticsData

    categories: Dictionary<ArticleListFilteredRequest> = {}

    moduleMode: string | undefined
    plugin: EventSubmitDelay
    shouldSendEvent: boolean
    temporaryRequestBody: ArticleListFilteredRequest

    constructor(plugin: EventSubmitDelay) {
        // extending tma sender needs to set
        super(ArticleListFilteredEvent.serviceEndpoint)

        this.additionalAnalyticsData = {
            filter: {
                available: undefined
            }
        }
        this.shouldSendEvent = false
        this.temporaryRequestBody = createEvent.initial()

        this.plugin = plugin.init(this)

        this.initializePortalEvents()
    }

    initializePortalEvents = () => {
        const globalChannel = channel("GLOBAL")
        globalChannel.subscribe("VEHICLE/RESET", () => { this.currentVehicleId = undefined })
        globalChannel.subscribe("VEHICLE/LOADED", (vehicle: Vehicle) => { this.currentVehicleId = vehicle.id }, true)
        globalChannel.subscribe("MVC/RESIZE", (data: any) => this.moduleMode = data.moduleMode)
        globalChannel.subscribe("WORKTASK/ID_CHANGED", () => { this.storage.body = null })
    }

    handle = (trackingData: TrackingModel<unknown>): boolean => {
        if (!trackingData.tmaEvent || trackingData.tmaEvent !== this.key || !trackingData.payload) {
            return false
        }

        const currentContext = this.storage.getContext()
        const tmpContext = this.temporaryRequestBody?.context

        if (tmpContext && !compareContext(currentContext, tmpContext)) {
            this.contextChanged(currentContext)
        }

        if (!this.temporaryRequestBody.searchStep) {
            if (!this.requestBody.searchStep) {
                this.temporaryRequestBody = createEvent.initial()
                this.shouldSendEvent = false
            } else {
                // if no searchstep is available and there is a stored (sessionStorage) requestbody use this
                this.temporaryRequestBody = this.requestBody
            }
        }

        this.handleEvent(trackingData)

        return false
    }

    private handleEvent = (trackingData: TrackingModel<unknown>) => {
        switch (trackingData.bundle) {
            case "parts":
            case "api": {
                if (Array.isArray(trackingData.action)) {
                    trackingData.action.forEach((action) => this.fillRequest(trackingData, action))
                } else {
                    this.fillRequest(trackingData, trackingData.action)
                }

                if (this.shouldSendEvent) {
                    this.saveChanges(this.temporaryRequestBody) // to make sure the current state is saved to the sessionStorage

                    try {
                        this.sendEvent(this.temporaryRequestBody)
                    } catch (ex) {
                        console.error(ex)
                    }

                    this.shouldSendEvent = false
                }

                break
            }
            default: { break }
        }
    }


    private fillRequest = (trackingData: TrackingModel<any>, action: string) => {
        const payload = trackingData.payload
        const { request, result } = payload

        switch (action) {
            case "get-articles": {
                this.temporaryRequestBody.extendedAssortment = request.extendedAssortment

                this.attachSearchQuery(payload)

                // let's try this... fittingsidefilter und fittingposition are not the same enums
                this.temporaryRequestBody.fittingSideFilter = request.fittingSideFilter as any

                this.attachArticleListInfo(request, result)
                this.attachSearchTree(request)
                // this.augmentFiltersWithArticleCount(result)
                this.attachProductGroupsToSupplier(result)

                this.setTrigger()
                this.saveRequestForCategorySwap()

                if (this.temporaryRequestBody.vehicleId != this.currentVehicleId) {
                    this.temporaryRequestBody.vehicleId = this.currentVehicleId
                }

                this.shouldSendEvent = !!this.temporaryRequestBody.articleList
                break
            }

            case "cdm": {
                // component did mount
                const vehicle = payload
                if (vehicle) {
                    this.temporaryRequestBody.vehicleId = vehicle?.id?.toString()
                }

                break
            }

            case "autocomplete": {
                const { hits } = payload

                if (hits && Array.isArray(hits))
                    this.autocompleteResults = hits

                if (this.temporaryRequestBody.origin && trackingData.source == "product-group-search") {
                    this.temporaryRequestBody.origin.trigger = AnalyticsEventTriggerType.ProductGroupKeywordSearch
                }
                break
            }

            case "trackAnalyticsData": {
                const { filter } = payload
                if (filter) {
                    this.attachFilters(filter)
                }
                break
            }

            case "nodeSelected":
            case "handleSelectNode":
            case "handleChangeBreadcrumbs": {

                const { nodeId, treeId, catalogContext, mode } = payload
                this.reset()

                this.temporaryRequestBody.origin.context = catalogContext || CatalogContextType.CatalogPartList

                const treeMode = mode || trackingData.source

                switch (treeMode) {
                    case "fast-click": {
                        this.temporaryRequestBody.origin.trigger = AnalyticsEventTriggerType.FastClickV1
                        this.temporaryRequestBody.origin.feature = ECatalogFeature.CatalogPartSearch
                        break
                    }
                    case "fast-click-2.0": {
                        this.temporaryRequestBody.origin.trigger = AnalyticsEventTriggerType.FastClickV2
                        this.temporaryRequestBody.origin.feature = ECatalogFeature.CatalogPartSearch
                        break
                    }
                    default: {
                        break
                    }
                }

                if (treeId || nodeId) {
                    this.temporaryRequestBody.searchTree = {
                        nodeId,
                        treeId
                    }
                }
            }

                break


            case "keyword-search-context": {
                if (this.temporaryRequestBody.eventStatus !== "initial") {
                    this.reset()
                }

                this.attachSearchQuery(payload)
                break
            }

            case "handleSearch": {
                // just reset. the reset process
                const { query } = this.temporaryRequestBody.querySearch || {}
                if (query != payload.query) {
                    this.reset()
                }
                break
            }

            case "handleExternalSearch": {

                this.reset()

                this.attachSearchQuery(payload)
                this.setSearchType()

                this.temporaryRequestBody.origin.context = getCatalogContext()
                this.temporaryRequestBody.origin.trigger = AnalyticsEventTriggerType.None

                break
            }

            case "increase-step-number": {
                this.temporaryRequestBody.searchStep = {
                    number: !this.temporaryRequestBody.searchStep ? 1 : ++this.temporaryRequestBody.searchStep.number,
                    processId: !this.temporaryRequestBody.searchStep ? uniqueId() : this.temporaryRequestBody.searchStep.processId
                }
                break
            }

            case "viewoptions-set": {
                // ARTLISTVIEWOPT
                // wird nicht aufgerufen
                const payload = trackingData.payload as { viewOptions: ArticleListViewOptions }
                if (payload && payload.viewOptions) {
                    const { viewOptions } = payload
                    this.temporaryRequestBody.viewOptions = viewOptions.compactView ? ArticleListViewOptionsType.Compact : ArticleListViewOptionsType.Detailed
                    this.temporaryRequestBody.viewOptions = viewOptions.showArticleImages ? this.temporaryRequestBody.viewOptions | ArticleListViewOptionsType.Images : this.temporaryRequestBody.viewOptions
                }
                break
            }

            case "viewoptions-changed": {
                // ARTLISTVIEWOPT
                // wird nicht aufgerufen
                const payload = trackingData.payload as { viewOptions: ArticleListViewOptions }
                if (payload && payload.viewOptions) {
                    const { viewOptions } = payload
                    this.temporaryRequestBody.viewOptions = viewOptions.compactView ? ArticleListViewOptionsType.Compact : ArticleListViewOptionsType.Detailed
                    this.temporaryRequestBody.viewOptions = viewOptions.showArticleImages ? this.temporaryRequestBody.viewOptions | ArticleListViewOptionsType.Images : this.temporaryRequestBody.viewOptions

                    if (this.temporaryRequestBody.searchStep) {
                        this.temporaryRequestBody.searchStep.number = this.temporaryRequestBody.searchStep.number + 1
                    }
                }
                break
            }

            case "set-search-filters": {
                this.searchFilters = payload.searchFilters
                if (this.temporaryRequestBody.querySearch?.searchType == QuerySearchType.Direct
                    || getCategoryTypeFromUrl(document.location.pathname) == "directSearch") {
                    this.temporaryRequestBody.searchFilters = this.searchFilters
                }

                break
            }
            case "swap-category": {
                this.reset() // now resetprocess to generate a new process and stepnumber
                break
            }
        }
    }

    setTrigger = () => {
        // if no trigger is set, let's suggest which should be set
        if (this.temporaryRequestBody.origin.trigger == AnalyticsEventTriggerType.None) {
            if (this.temporaryRequestBody.querySearch?.query) {
                this.temporaryRequestBody.origin.trigger = AnalyticsEventTriggerType.CatalogPartDirectSearch
            } else {
                this.temporaryRequestBody.origin.trigger = AnalyticsEventTriggerType.None // AnalyticsEventTriggerType.CatalogPartDirectSearch
            }
        }

        // "this.setSearchType" has changed trigger to "ProductGroupKeywordSearch" for workflow "Dashboard -> FastClick -> Article list"
        if (this.temporaryRequestBody.origin.trigger != AnalyticsEventTriggerType.FastClickV1 &&
            this.temporaryRequestBody.origin.trigger != AnalyticsEventTriggerType.FastClickV2 &&
            this.temporaryRequestBody.origin.context != CatalogContextType.Dashbord &&
            this.temporaryRequestBody.origin.context != CatalogContextType.StartPage) {
            this.setSearchType()
        }
    }

    saveRequestForCategorySwap = () => {
        const searchType = this.temporaryRequestBody.querySearch?.searchType

        switch (searchType) {
            case QuerySearchType.Direct: {
                this.categories.directSearch = this.temporaryRequestBody
                break
            }
            case QuerySearchType.Synonym: {
                this.categories.vehicleParts = this.temporaryRequestBody
                break
            }
        }
    }

    reset = () => {
        const { context, searchStep } = this.temporaryRequestBody
        context && this.storage.delete(context)
        searchStep && this.storage.removeModifiedState(searchStep.processId)

        this.resetTemporaryRequest()
    }

    resetTemporaryRequest = () => {
        const initialEvent = createEvent.initial(this.temporaryRequestBody.vehicleId)
        this.temporaryRequestBody = initialEvent
        this.shouldSendEvent = false
    }

    contextChanged = (newContext: StoreContext) => {
        this.saveChanges(this.temporaryRequestBody)
        this.initOrRestoreContent(newContext)
    }

    initOrRestoreContent = (newContext: StoreContext) => {
        const storedRequest = this.storage.loadContent(newContext)
        if (storedRequest && Object.keys(storedRequest)) {
            this.temporaryRequestBody = storedRequest
        } else {
            this.resetTemporaryRequest()
            this.temporaryRequestBody.context = newContext
        }

        if (this.temporaryRequestBody.context?.contextId.includes("modal")) {
            this.temporaryRequestBody.eventStatus = "completed"
        }
    }

    saveChanges = (newRequestBody: ArticleListFilteredRequest) => {
        if (newRequestBody.context && isModal(newRequestBody.context?.contextId)) {
            newRequestBody.eventStatus = "completed" // modal event s will not be saved and send directly
            return
        }
        this.requestBody = newRequestBody
    }

    loadRequest = () => {
        return this.temporaryRequestBody
    }

    private attachSearchQuery = (payload: any) => {
        const { query } = payload

        let searchQuery: string | undefined = query || getSearchQueryFromUrl()


        if (!searchQuery || searchQuery == "") {
            this.temporaryRequestBody.querySearch = undefined
            return
        }

        this.temporaryRequestBody.querySearch = {
            autocompleteEntryExists: !!this.autocompleteResults.find(
                (item) => !!searchQuery && item.toLowerCase().startsWith(searchQuery.toLowerCase())
            ),
            query: searchQuery,
            searchType: QuerySearchType.Undefined
        }

        this.setSearchType(payload.categoryType, payload.fromWidget)
    }



    private setSearchType = (categoryType?: CategoryType, fromWidget?: boolean) => {

        switch (this.temporaryRequestBody.origin.context) {
            case CatalogContextType.TechnicalDataHaynes:
            case CatalogContextType.TechnicalDataTecRmi:
            case CatalogContextType.TechnicalDataAutodata:

            case CatalogContextType.ServiceDataHaynes:
            case CatalogContextType.ServiceDataTecRmi:
            case CatalogContextType.ServiceDataAutodata:

            case CatalogContextType.FastCheck:
            case CatalogContextType.FastCalculation:
            case CatalogContextType.FastService:

            case CatalogContextType.AsWegasRepairEstimation:
            case CatalogContextType.DatRepairEstimation:
            case CatalogContextType.EurotaxRepairEstimation:
            case CatalogContextType.GlassRepairEstimationDat:

            case CatalogContextType.GpiDat:
            case CatalogContextType.DatGpiRapidCalculation:
            case CatalogContextType.GpiEurotax:
            case CatalogContextType.GpiTemot:
            case CatalogContextType.GpiTecRmi:

            case CatalogContextType.TyresTecRmi:
            case CatalogContextType.Eds:

            case CatalogContextType.DirectBuyShoppingCart: {
                return
            }

            case CatalogContextType.TruckVin:
            case CatalogContextType.MyTruck:
            case CatalogContextType.MyTruckPlus:
            case CatalogContextType.TruckGarage: {

                if (this.requestBody.querySearch && this.requestBody.querySearch.searchType != QuerySearchType.Direct) {
                    this.requestBody.querySearch.searchType = QuerySearchType.Direct
                }

                return
            }
        }

        const activeCategory = categoryType || getCategoryTypeFromUrl(window.document.location.href)
        let searchType = QuerySearchType.Undefined

        switch (activeCategory) {
            case "directSearch": {
                searchType = QuerySearchType.Direct
                this.temporaryRequestBody.searchFilters = this.searchFilters
                this.temporaryRequestBody.origin.trigger = AnalyticsEventTriggerType.CatalogPartDirectSearch
                this.temporaryRequestBody.origin.context = !fromWidget ? CatalogContextType.CatalogPartList :
                    (document.location.pathname == "/" ? CatalogContextType.StartPage : CatalogContextType.Dashbord)
                this.temporaryRequestBody.origin.feature = ECatalogFeature.CatalogPartSearch

                break
            }

            case "vehicleParts": {
                searchType = QuerySearchType.Synonym
                this.temporaryRequestBody.origin.trigger = AnalyticsEventTriggerType.ProductGroupKeywordSearch
                this.temporaryRequestBody.searchFilters = []
                this.temporaryRequestBody.origin.context = !fromWidget ? CatalogContextType.CatalogPartList :
                    (document.location.pathname == "/" ? CatalogContextType.StartPage : CatalogContextType.Dashbord)
                this.temporaryRequestBody.origin.feature = ECatalogFeature.CatalogPartSearch

                break
            }

            case "partsIndicator": {
                searchType = QuerySearchType.ProductGroup
                this.temporaryRequestBody.origin.trigger = AnalyticsEventTriggerType.PartsIndicator
                this.temporaryRequestBody.searchFilters = []
                this.temporaryRequestBody.origin.context = !fromWidget ? CatalogContextType.CatalogPartList : CatalogContextType.Dashbord
                this.temporaryRequestBody.origin.feature = ECatalogFeature.CatalogPartSearch

                break
            }

            case "dtcSearch": {
                searchType = QuerySearchType.ProductGroup
                this.temporaryRequestBody.origin.trigger = !fromWidget ? AnalyticsEventTriggerType.None : AnalyticsEventTriggerType.FastDtc
                this.temporaryRequestBody.searchFilters = []
                this.temporaryRequestBody.origin.context = !fromWidget ? CatalogContextType.FastDtc : CatalogContextType.Dashbord
                this.temporaryRequestBody.origin.feature = ECatalogFeature.CatalogPartSearch

                break
            }
        }

        if (this.temporaryRequestBody.querySearch && searchType != this.temporaryRequestBody.querySearch?.searchType) {
            this.temporaryRequestBody.querySearch.searchType = searchType
        }
    }

    attachFilters = (filters: any) => {
        filters.available && this.attachSupplierFilters(filters.available.dataSupplierFilters || [])
        filters.available && this.attachProductGroupFilters(filters.available.productGroupFilters || [])
    }

    attachSupplierFilters = (suppliers: Array<Filter>) => {
        if (suppliers.length <= 0) {
            return
        }

        this.temporaryRequestBody.dataSupplierFilters = []

        suppliers.forEach((supplier) => {
            const { dataSupplierFilters } = this.temporaryRequestBody
            dataSupplierFilters &&
                dataSupplierFilters.push({
                    articleCount: supplier.articleCount || 0,
                    id: supplier.id,
                    isSelected: !!supplier.isSelected,
                    priority: supplier.priority,
                })
        })
    }

    attachProductGroupFilters = (productGroupFilters: Array<Filter>) => {
        if (productGroupFilters.length <= 0) {
            return
        }

        this.temporaryRequestBody.productGroupFilters = []

        productGroupFilters.forEach(productGroupFilter => {
            const { productGroupFilters } = this.temporaryRequestBody

            productGroupFilters &&
                productGroupFilters.push({
                    articleCount: productGroupFilter.articleCount || 0,
                    id: productGroupFilter.id,
                    isSelected: !!productGroupFilter.isSelected,
                    priority: productGroupFilter.priority,
                })
        })
    }

    // artikel suche .-> suchfeld filter(suchbereiche)
    attachOeReferences = (oeReferences: Array<Filter>) => {
        oeReferences.forEach((oeReference) => {
            this.temporaryRequestBody.searchFilters?.push({
                articleCount: oeReference.articleCount || 0,
                id: SearchFilterType.OeReference,
                isSelected: !!oeReference.isSelected
            })
        })
    }

    attachArticleListInfo(request: GetArticlesRequest, result: GetArticlesResponse | undefined) {
        let wholesalerArticleCount = 0
        const productGroupDataSuppliers: Array<ProductGroupDataSupplier> = []

        result?.articles?.forEach((article: Article) => {
            if (article.traderArticleNo) {
                ++wholesalerArticleCount
            }

            if (article.supplier) {
                const { supplier, productGroup } = article
                const productGroupDataSupplierPair = {
                    dataSupplierId: supplier.id,
                    productGroupId: productGroup.id,
                }

                if (
                    !productGroupDataSuppliers.find(
                        (item) =>
                            item.dataSupplierId == productGroupDataSupplierPair.dataSupplierId &&
                            item.productGroupId == productGroupDataSupplierPair.productGroupId
                    )
                ) {
                    productGroupDataSuppliers.push(productGroupDataSupplierPair)
                }
            }
        })

        const maxArticleCount = result?.articleListCount ?? 0
        const pageSize = result?.pageSize ?? request.pageSize
        const pageIndexMax = getPageIndexMax(maxArticleCount, pageSize)

        // const previousCount = this.temporaryRequestBody.articleList?.articleCount ?? 0
        const articleListInfo: ArticleListInfo = {
            articleCount: result?.articles?.length ?? 0,
            maxArticleCount,
            paging: {
                pageIndex: result?.pageIndex ?? request.pageIndex,
                pageSize,
                pageIndexMax,
            },
            wholesalerArticleCount,
            productGroupDataSuppliers,
        }

        this.temporaryRequestBody.articleList = articleListInfo
    }

    attachSearchTree(request: GetArticlesRequest) {
        if (request.nodeId || request.treeId) {
            this.temporaryRequestBody.searchTree = {
                nodeId: request.nodeId,
                treeId: request.treeId
            }
        }
    }

    augmentFiltersWithArticleCount = (result: GetArticlesResponse) => {
        if (!result || !result?.articles || result?.articles?.length == 0) {
            return
        }

        const { dataSupplierFilters, productGroupFilters } = this.temporaryRequestBody
        const { articles } = result

        if (dataSupplierFilters) {
            dataSupplierFilters.forEach((filter) => {
                const articleCount = articles.filter((article: Article) => article.supplier.id == filter.id).length
                filter.articleCount = articleCount
            })
        }

        if (productGroupFilters) {
            productGroupFilters.forEach((filter) => {
                const articleCount = articles.filter((article: Article) => article.productGroup.id == filter.id).length
                filter.articleCount = articleCount
            })
        }
    }

    attachProductGroupsToSupplier = (result: GetArticlesResponse) => {
        result.articles.forEach((article: Article) => {
            if (!article?.supplier?.id || !article?.productGroup?.id) { return }

            this.temporaryRequestBody.productGroupsPerSupplier[article.supplier.id] = article.productGroup.id
        })
    }

}
