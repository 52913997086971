import { styled } from "@mui/material"
import { CellContentPosition, TableVariants } from "./interfaces"
import { Element } from "./elements"

type TableCellProps = {
    colCount: number
    rowIndicatorColor?: string
    maxWidth?: number
    variant?: TableVariants
    alignContent?: CellContentPosition
    overflowCell?: boolean
    transparent?: boolean
    textColor?: string
    className?: string
    /* The property font size can be used to provide number of pixels */
    fontSize?: number
}

type OverflowWrapperProps = { variant?: TableVariants; maxWidth?: number; fontSize?: string | number }

function shouldForwardProp(prop: PropertyKey) {
    return !["maxWidth", "variant", "fontSize"].includes(prop as string)
}

const OverflowWrapper = styled("span", { shouldForwardProp })<OverflowWrapperProps>(({ variant, maxWidth, fontSize }) => ({
    ...(variant === "small" && {
        padding: "5px 4px 3px 4px",
        "*": {
            fontSize: "12px",
            "&.MuiTypography-root": {
                fontSize: "12px",
            },
        },
    }),
    ...(maxWidth && {
        maxWidth: `${maxWidth}px`,
        "*": {
            ...(variant === "small" && {
                fontSize: "12px",
            }),
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    }),
    ...(fontSize && {
        "*": {
            fontSize: `${fontSize}px`,
        },
    }),
}))

export const TableCell: React.FC<TableCellProps> = (props) => {
    return (
        <Element
            colCount={props.colCount}
            alignContent={props.alignContent}
            variant={props.variant}
            rowIndicatorColor={props.rowIndicatorColor}
            transparent={props.transparent}
            textColor={props.textColor}
            className={props.className}
            fontSize={props.fontSize}
        >
            {!props.overflowCell && (
                <OverflowWrapper variant={props.variant} maxWidth={props.maxWidth} fontSize={props.fontSize}>
                    {props.children}
                </OverflowWrapper>
            )}

            {props.overflowCell && <>{props.children}</>}
        </Element>
    )
}
