import { Box, Skeleton, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { TreeNode as TreeNodeModel } from "@tm/models"
import { Bestseller } from "./Bestseller"
import { TreeNode } from "./TreeNode"

const Grid = styled("div", { shouldForwardProp: (prop) => prop !== "rows" })<{ rows: number }>(({ rows }) => ({
    display: "grid",
    gridTemplateRows: `repeat(${rows}, minmax(25px, 25px))`,
    gridAutoFlow: "column",
    columnGap: "1em",
    rowGap: "0.5em",
    height: "100%",
    padding: "1em 1em 1.5em 1em",
}))

// last-row
const BestsellerContainer = styled("div")({
    gridRowStart: 1,
    gridRowEnd: 10,
    height: "100%",
    width: "100%",
})

type Props = {
    bestseller?: boolean
    selectedNode?: TreeNodeModel
    onNodeClick: (node: TreeNodeModel, fastClick?: boolean) => void
    isLoading?: boolean
    applyProductGroups?: (productGroupIds: number[]) => void
    fastCalculation?: (productGroupIds: number[]) => void
    hideFastCalcButton?: boolean
    maxSelectableProductGroups?: number
}

export function ListView(props: Props) {
    const { bestseller, selectedNode } = props
    const { translateText } = useLocalization()
    const skeletons = Array.from(Array(30).keys()).map((_, index) => {
        return <Skeleton key={`SKel_${index}`} height={30} width={Math.floor(Math.random() * (350 - 251) + 250)} />
    })

    if (props.isLoading) {
        return (
            <Grid rows={10}>
                {skeletons.map((skel) => {
                    return skel
                })}
            </Grid>
        )
    }

    if (selectedNode?.hasChildNodes) {
        const numberOfChildNodes = selectedNode?.childNodes?.length
        const gridTemplateColumns = numberOfChildNodes && numberOfChildNodes < 20 ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"
        const gridColumnStart = numberOfChildNodes && numberOfChildNodes < 20 ? "3" : "4"

        // at some cases, we have more then 30 items so we need more then 10 rows to display this correct
        let gridRows = 10
        if (numberOfChildNodes && numberOfChildNodes > 30) {
            const maxRows = Math.round(numberOfChildNodes / 3)
            gridRows = maxRows
        }

        return (
            <Grid sx={{ gridTemplateColumns }} rows={gridRows}>
                {bestseller && props.selectedNode?.topProductGroups && props.selectedNode.hasChildNodes && (
                    <BestsellerContainer sx={{ gridColumnStart }}>
                        <Bestseller
                            Bestseller={props.selectedNode?.topProductGroups}
                            onNodeClick={props.onNodeClick}
                            applyProductGroups={props.applyProductGroups}
                            fastCalculation={props.fastCalculation}
                            hideFastCalcButton={props.hideFastCalcButton}
                            maxSelectableProductGroups={props.maxSelectableProductGroups}
                        />
                    </BestsellerContainer>
                )}
                {props.selectedNode?.childNodes &&
                    props.selectedNode.childNodes.length > 0 &&
                    props.selectedNode?.childNodes.map((node) => {
                        return <TreeNode key={`listViewNode${node.id}`} node={node} onClick={props.onNodeClick} hideIcon />
                    })}
            </Grid>
        )
    }

    return <Box>{translateText(12775)}</Box>
}
