import { encodeUniqueId } from "../../helpers/id"
import { CatalogContextType, getCurrentModuleMode } from "@tm/models"
import { ContextTypes } from "../models/contextTypes"

export function getCatalogContext(): CatalogContextType {
    const path = decodeURIComponent(document.location.pathname)
    const workTaskId = getWorkTaskId()
    if (path == "/") {
        return CatalogContextType.StartPage
    }else if (path == "/"+workTaskId) {
        return CatalogContextType.Dashbord
    }

    let context: CatalogContextType = CatalogContextType.None

    for (const contextType of ContextTypes) {
        for (const cPath of contextType.paths) {
            if (path.indexOf(cPath) >= 0) {
                context = contextType.context
                break
            }
        }
    }

    const moduleMode = getCurrentModuleMode()

    switch (moduleMode) {

        // workaround - GPI DAT has 2 mods with the same url!
        case "GPI_RAPID_CALCULATION": {
            switch (context) {
                case CatalogContextType.GpiDat: {
                    context = CatalogContextType.DatGpiRapidCalculation
                    break
                }
                default: {
                    break
                }
            }
        }

        // workaround - SD and TD MVC modules have the same url!
        case "SD": {
            switch (context) {
                case CatalogContextType.TechnicalDataHaynes: {
                    context = CatalogContextType.ServiceDataHaynes
                    break
                }
                case CatalogContextType.TechnicalDataTecRmi: {
                    context = CatalogContextType.ServiceDataTecRmi
                    break
                }
                case CatalogContextType.TechnicalDataAutodata: {
                    context = CatalogContextType.ServiceDataAutodata
                    break
                }
                default: {
                    break
                }
            }
        }
    }

    return context
}
export function getWorkTaskId() {
    let wkid = (window as any).__NEXT_WORKTASKID__ && encodeUniqueId((window as any).__NEXT_WORKTASKID__)
    if (!wkid) {
        wkid = getWorkTaskIdFromLocation()
    }
    return wkid
}

export function getWorkTaskIdFromLocation(): string | undefined {
    const match = /^\/[\d\w]{22}\/?/.exec(document.location.pathname)
    let workTaskId: string | undefined

    if (match) {
        workTaskId = match[0].replace(/\//g, "")
    }

    return workTaskId
}


const RemovableParams = ["forceReload"]
/**
 * @description removes specific params, like forceReload from context url
 * @param contextId string
 * @returns qualified contextId
 */
export function cleanContextUrl(contextId: string): string {
    if (contextId.indexOf('?') < 0) { return contextId }

    const params = new URLSearchParams(contextId)
    RemovableParams.forEach(param => params.delete(param))

    return decodeURIComponent(params.toString())

}
