import { AvailabilityStatus } from "@tm/models"
import { IndicatorPosition } from "./Availability"
import { Theme } from "../.."

export function getBackgroundColor(theme: Theme, mapping: string, position: IndicatorPosition, status: AvailabilityStatus) {
    const mappingConfig = theme.overwrites?.components?.availability?.[mapping]
    const statusConfig = mappingConfig?.[status]
    const positionConfig = statusConfig?.indicator?.[position]

    // Try to get the color for the specific position
    if (positionConfig?.backgroundColor) {
        return positionConfig.backgroundColor
    }

    // Try to use the general color
    if (statusConfig?.indicatorBackgroundColor) {
        return statusConfig.indicatorBackgroundColor
    }

    // Use the default colors
    switch (status) {
        case AvailabilityStatus.Available:
        case AvailabilityStatus.AvailableInAlternativeWarehouse:
        case AvailabilityStatus.ManufacturerAvailable:
            return "#c2e617"
        case AvailabilityStatus.AvailableInCentralWarehouse:
        case AvailabilityStatus.NightExpress:
            if (position === 1 || position === 2 || position === 3) {
                return "#48ad4d"
            }
            break
        case AvailabilityStatus.PartiallyAvailable:
        case AvailabilityStatus.PartiallyAvailableInAlternativeWarehouse:
        case AvailabilityStatus.PartiallyAvailableInCentralWarehouse:
            if (position === 1 || position === 2) {
                return "#f3ae21"
            }
            break
        case AvailabilityStatus.NotAvailable:
        case AvailabilityStatus.ManufacturerUnavailable:
        case AvailabilityStatus.NotYetRequested:
        case AvailabilityStatus.ManufacturerNotYetRequested:
        case AvailabilityStatus.NoLongerAvailable:
        case AvailabilityStatus.Undefined:
        case AvailabilityStatus.Unknown:
        case AvailabilityStatus.NotYetDefined:
        default:
            return undefined
    }
}

export function getBorderColor(theme: Theme, mapping: string, status: AvailabilityStatus) {
    const mappingConfig = theme.overwrites?.components?.availability?.[mapping]
    const statusConfig = mappingConfig?.[status]

    // Try to use the border color
    if (statusConfig) {
        return statusConfig.borderColor
    }

    // Use the default colors
    switch (status) {
        case AvailabilityStatus.Available:
        case AvailabilityStatus.AvailableInAlternativeWarehouse:
        case AvailabilityStatus.ManufacturerAvailable:
            return "#c2e617"
        case AvailabilityStatus.AvailableInCentralWarehouse:
        case AvailabilityStatus.NightExpress:
            return "#48ad4d"
        case AvailabilityStatus.PartiallyAvailable:
        case AvailabilityStatus.PartiallyAvailableInAlternativeWarehouse:
        case AvailabilityStatus.PartiallyAvailableInCentralWarehouse:
            return "#f3ae21"
        case AvailabilityStatus.NotAvailable:
        case AvailabilityStatus.ManufacturerUnavailable:
        case AvailabilityStatus.NotYetRequested:
        case AvailabilityStatus.ManufacturerNotYetRequested:
            return "#f44336"
        case AvailabilityStatus.NoLongerAvailable:
        case AvailabilityStatus.Undefined:
        case AvailabilityStatus.Unknown:
        case AvailabilityStatus.NotYetDefined:
            return "#e2e2e2"
        default:
            return undefined
    }
}
