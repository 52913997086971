import * as React from "react"
import * as PropTypes from 'prop-types'
import { RouterChildContext, matchPath, useRouteMatch, useHistory, useLocation, match } from "react-router"
import { History, Location } from "history"
import { IContext } from "."
import { LayoutProps } from "./layout"
import { executePlugin } from "./lib/executePlugin"
import { ModuleConfig, Broadcast } from "./models/configuration"
import { Dictionary } from "./models/dictionary"
import { dictionaryToArray } from "./helpers"

export type ModuleProps = {
    context: IContext
    config: ModuleConfig
    layout: React.ComponentClass<LayoutProps>
    children: Dictionary<Array<React.ReactNode>>
    moduleKey: string
    route: { path: string, exact: boolean }
    props?: any
}

type ModuleClassProps = ModuleProps & { routeMatch: match, history: History }

export type ModuleState = {
    key: string
    visible: boolean
}


class MC extends React.Component<ModuleClassProps, ModuleState> {

    static contextTypes = {
        view: PropTypes.string,
        urlSplitPosition: PropTypes.number,
        routes: PropTypes.array
    }

    static childContextTypes = {
        routes: PropTypes.array
    }

    context: RouterChildContext<any> & {
        view: string
        urlSplitPosition?: number
        routes: Array<string>
    }

    getChildContext() {
        let routes = this.context.routes || []
        let {path} = this.props.routeMatch
        if (path.length > 1 && /\/$/.test(path)) {
            path = path.substr(0, path.length - 1)
        }
        if (routes.indexOf(path) == -1) {
            routes = [...routes, path]
        }
        return { routes }
    }

    getContext() {
        return this.getChildContext()
    }

    removeRemountOnRouteChangeListener?: () => void

    constructor(props: ModuleClassProps) {
        super(props)
        this.state = { key: "module", visible: true }

        this.setVisibility = this.setVisibility.bind(this)
    }

    UNSAFE_componentWillMount() {
        const { config, history, routeMatch } = this.props
        this.startBroadcast()

        // To force remount we need a key which changes on url change
        if (config.remountOnRouteChange) {
            const match = matchPath(history.location.pathname, routeMatch)
            this.setState({ key: match ? match.url : history.location.pathname })
            this.removeRemountOnRouteChangeListener = history.listen(this.handleRemountOnRouteChange.bind(this))
        }

        executePlugin(this.props.context, this.props, "MODULE/MOUNT")
    }

    componentDidMount() {
        executePlugin(this.props.context, this.props, "MODULE/DID_MOUNT")
    }

    componentDidUpdate() {
        executePlugin(this.props.context, this.props, "MODULE/UPDATE")
    }

    componentWillUnmount() {
        this.removeRemountOnRouteChangeListener && this.removeRemountOnRouteChangeListener()
        executePlugin(this.props.context, this.props, "MODULE/UNMOUNT")
    }

    startBroadcast = () => {
        const { config } = this.props
        if (config.broadcast) {
            if (Array.isArray(config.broadcast)) {
                config.broadcast.forEach(this.sendBroadcast.bind(this))
            } else {
                this.sendBroadcast(config.broadcast)
            }
        }
    }

    sendBroadcast(broadcast: Broadcast) {
        this.props.context.rootActions$.next({
            bundle: "*",
            module: "*",
            component: "*",
            action: {
                type: broadcast.action,
                payload: broadcast.payload
            },
            received: [],
        })
    }

    handleRemountOnRouteChange(location: Location) {
        const match = matchPath(location.pathname, this.props.routeMatch)
        const currentKey = this.state.key // For some reason in special cases (for example NEXT-17596) the state gets updated immediately. so we store it here before updating.
        if (match && match.url != currentKey) {
            this.startBroadcast()
            this.setState({ key: match.url })
        }

        if (this.props.config.clearStoresOnRouteChange) {
            // Clear all state under the module
            if (!match || match.url != currentKey) {
                const { context } = this.props
                const storesToClear = Object.keys(context.stores).filter(key => key.indexOf(this.props.moduleKey) === 0).map(key => context.stores[key])
                storesToClear.forEach(store => store.dispatch({ type: "@@redux/INIT", }))
            }
        }
    }

    setVisibility(visible: boolean) {
        this.setState({ visible })
    }

    render(): React.ReactNode {
        const { layout, children, config, route } = this.props
        const className = `module ${config.className || ""}`

        function getNextSwitch() {
            const result: Array<Array<React.ReactElement>> = []
            dictionaryToArray(children).forEach(inner => {
                if (Array.isArray(inner.value)) {
                    inner.value.forEach((child: any) => {
                        if (child && child.props && child.props.className && child.props.className.indexOf("layout__switch") != -1) {
                            result.push([child])
                        }
                    })
                }
            })
            return result
        }

        if (children && this.context.urlSplitPosition && route.path.length <= this.context.urlSplitPosition) {
            return getNextSwitch()
        }

        const style = this.state.visible ? undefined : { display: "none" }

        const Layout = layout

        return (
            <div className={className} key={this.state.key} style={style}>
                <Layout children={children} slotClassNames={config.slotClassNames} props={this.props.props} setModuleVisibility={this.setVisibility} />
            </div>
        )
    }
}


export function ModuleComponent(props: ModuleProps) {

    const routeMatch = useRouteMatch()
    const history = useHistory()
    
    return <MC {...{...props, routeMatch, history}} />
}