import { ArticleListFilteredEventHelper } from "../events/article-list-filtered/event-helper"
import { PremiumUniPartsSearchEventHelper } from "../events/premium-uni-parts-search"
import { VehicleSelectionEventHelper } from "../events/vehicle-selection"
import { AddToBasket } from "../events/add-to-basket/event-helper"
import { ModuleCalledRequestHelper } from "../events/module-called"
import { ArticleListErpInfoShownHelper } from "../events/article-list-erp-info-shown/event-helper"
import { AddRepairTimeListEventHelper } from "../events/add-repairtime-list"
import { ArticleEventHelper } from "../events/article/event-helper"
import { SharedHelper } from "./shared"
import { ArticleDetailsPDFEventHelper } from "../events/articlePDFClick/event-helper"
import { DetailsFormCallEventHelper } from "../events/detailsFormCall/event-helper"
import { ArticleComparisonEventHelper } from "../events/articleComparison/event-helper"

export const TmaHelper = {
    /**
     * @deprecated use ArticleListFiltered
     */
    articleListFiltered: ArticleListFilteredEventHelper,
    /**
     * @deprecated use ArticleListFiltered
     */
    repairTimes: { Module: { Called: ModuleCalledRequestHelper } },

    /**
     * @deprecated use ArticleListFiltered
     */
    RepairTimes: { Module: { Called: ModuleCalledRequestHelper } },

    ModuleCalled: ModuleCalledRequestHelper,
    ArticleListFiltered: ArticleListFilteredEventHelper,
    VehicleSelection: VehicleSelectionEventHelper,
    Erp: ArticleListErpInfoShownHelper,
    ...PremiumUniPartsSearchEventHelper,
    ...AddToBasket,
    Shared: SharedHelper,
    ...AddRepairTimeListEventHelper,
    Article: ArticleEventHelper,
    ArticleDetailsPDFClick: ArticleDetailsPDFEventHelper,
    DetailsFormCall: DetailsFormCallEventHelper,
    ArticleComparison: ArticleComparisonEventHelper,
}
