import { styled } from "@mui/material"
import { BORDER_RADIUS, LEFT_ROW_INDICATOR } from "../configs"
import { CellContentPosition, TableVariants } from "../interfaces"

type ElementProps = {
    variant?: TableVariants
    rowIndicatorColor?: string
    colCount: number
    alignContent?: CellContentPosition
    transparent?: boolean
    textColor?: string
    className?: string
    /* The property font size can be used to provide number of pixels */
    fontSize?: number
}

function shouldForwardProp(prop: PropertyKey) {
    return !["colCount", "alignContent", "variant", "maxWidth", "rowIndicatorColor", "fontSize", "textColor", "transparent"].includes(prop as string)
}

export const Element = styled("span", { shouldForwardProp })<ElementProps>((props) => {
    const { theme, colCount, alignContent, variant, rowIndicatorColor, transparent, textColor, className, fontSize } = props
    const elementListOverwrites = theme.overwrites?.components?.elementList ?? {}
    const overwritesBorderLeftWidth = elementListOverwrites?.backgroundColor?.borderLeftWidth
    const borderLeftWidth = overwritesBorderLeftWidth || LEFT_ROW_INDICATOR

    return {
        ...theme.typography.label,
        ...(textColor && {
            color: textColor,
        }),
        opacity: undefined,
        zIndex: 1,
        className,
        display: "flex",
        alignItems: "center",
        justifyContent: alignContent || "start",
        padding: variant === "small" ? theme.spacing("1px", "2px", "1px", "2px") : theme.spacing(1),
        borderTop: `1px solid ${transparent ? "transparent" : rowIndicatorColor || theme.palette.grey[200]}`,

        ...(variant === "small" && {
            "*": {
                fontSize: "12px",
                lineHeight: "1.2",
            },
        }),
        ...(fontSize && {
            "*": {
                fontSize: `${fontSize ?? 14}px`,
            },
        }),

        [`&:nth-of-type(${colCount}n+1)`]: {
            borderLeft: `${borderLeftWidth}px solid ${transparent ? "transparent" : rowIndicatorColor || theme.palette.grey[300]}`,
            borderRadius: `${BORDER_RADIUS} 0 0 0`,
            paddingLeft: variant === "small" ? theme.spacing(0.8) : theme.spacing(2),
        },
        [`&:nth-of-type(${colCount})`]: {
            borderRadius: `0 ${BORDER_RADIUS} 0 0`,
            borderRight: `1px solid ${transparent ? "transparent" : rowIndicatorColor || theme.palette.grey[200]}`,
            paddingRight: variant === "small" ? theme.spacing(0.5) : theme.spacing(2),
        },
    }
}) as React.ElementType<ElementProps & React.HTMLAttributes<HTMLDivElement>>
