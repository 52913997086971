import { ChangeEvent, KeyboardEvent, ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState, MouseEvent } from "react"
import { TextField, TextFieldProps, styled, filledInputClasses, colors, buttonClasses } from "@mui/material"
import { alpha } from "@mui/system"
import { Button, IconButton } from "../../generics/button"
import { Icon } from "../../generics/Icons"

const SearchTextField = styled(TextField)(({ theme }) => ({
    [`.${filledInputClasses.root}`]: {
        padding: 0,
        border: `1px solid ${theme.overwrites?.components?.textfield?.border?.color || "#e2e2e1"}`,
        borderRadius: theme.radius?.default || "3px",
        backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:before": {
            content: "none",
        },
        "&:after": {
            content: "none",
        },

        "&:hover": {
            backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
        },
        [`&.${filledInputClasses.focused}`]: {
            backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
            borderColor: theme.palette.primary.main,
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        },
        [`&.${filledInputClasses.error}`]: {
            borderColor: theme.palette.error.main,
            borderBottom: `4px solid ${theme.palette.error.main}`,
            marginBottom: "0px",
            [`&.${filledInputClasses.focused}`]: {
                boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 2px`,
            },
        },
    },
    [`.${filledInputClasses.input}`]: {
        padding: 8,
        boxSizing: "content-box",
        fontSize: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
        lineHeight: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
        height: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
    },
}))

const SearchButton = styled(Button)({
    [`&.${buttonClasses.root}.${buttonClasses.contained}`]: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
})

type SearchFieldProps = Omit<TextFieldProps, "value" | "onChange"> & {
    value: string
    onChange(value: string): void
    onStartSearch(): void
}

function SearchFieldComponent(props: SearchFieldProps, ref: ForwardedRef<HTMLDivElement>) {
    const [value, setValue] = useState(props.value ?? "")
    const inputRef = useRef<HTMLInputElement>()

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const handleClickClear = useCallback(() => {
        props.onChange("")
        inputRef.current?.focus()
    }, [props])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.currentTarget.value)
        },
        [props]
    )

    const handleKeyStroke = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Enter") {
                props.onStartSearch()
            }
            props.onKeyUp?.(e)
        },
        [props]
    )

    const handleClickSearch = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            props.onStartSearch()
            e.stopPropagation()
        },
        [props]
    )

    return (
        <SearchTextField
            autoComplete="off"
            {...props}
            value={value}
            onChange={handleChange}
            onKeyUp={handleKeyStroke}
            ref={ref}
            variant="filled"
            inputProps={{
                ref: inputRef,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
                endAdornment: (
                    <>
                        {!!props.value && (
                            <IconButton size="small" onClick={handleClickClear}>
                                <Icon name="close" />
                            </IconButton>
                        )}
                        <SearchButton startIcon={<Icon name="search" />} onClick={handleClickSearch} />
                    </>
                ),
            }}
        />
    )
}

export const SearchField = forwardRef(SearchFieldComponent)
