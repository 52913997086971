import { AnalyticsEventTriggerType, AvailabilityStatus, CatalogContextType } from "@tm/models"
import { uniqueId } from "../../.."
import { getCatalogContext } from "../../helper/context"
import { TmaEvent, TrackingModel } from "../../models/models"
import { TmaSender } from "../tma-sender"
import { ArticleImageClick } from "./models"

export class ArticleEvent extends TmaSender<ArticleImageClick> implements TmaEvent {
    key = "ARTICLE_IMAGE_CLICKED"

    static serviceEndpoint = "/articles/imageClick"

    constructor() {
        super(ArticleEvent.serviceEndpoint)

        this.requestBody = {
            origin: {
                context: CatalogContextType.None,
                trigger: AnalyticsEventTriggerType.None,
            },
        }
    }

    handle = (trackingData: TrackingModel<unknown>): boolean => {
        if (trackingData.tmaEvent && trackingData.tmaEvent == this.key) {
            if (!this.requestBody.searchStep) {
                this.requestBody = {
                    searchStep: {
                        number: 1, // can be delete later on, but set searchStep.number to 1 when inside reset()
                        processId: uniqueId(),
                    },
                    origin: {
                        context: getCatalogContext(),
                        trigger: AnalyticsEventTriggerType.None,
                    },
                }
            }

            if (trackingData.payload && trackingData.bundle == "parts") {
                if (Array.isArray(trackingData.action)) {
                    trackingData.action.forEach((action) => this.fillRequest(trackingData, action))
                } else {
                    this.fillRequest(trackingData, trackingData.action)
                }
            }

            return false
        }

        return false
    }

    fillRequest = (trackingData: TrackingModel<any>, action: string) => {
        const { payload } = trackingData
        const { article, quantityModifiers, vehicleId } = trackingData.payload

        switch (action) {
            case "article-image-click": {
                if (article) {
                    this.requestBody.catalogPart = {
                        dataSupplierArticleNumber: article.supplierArticleNo,
                        dataSupplierId: article.supplier.id,
                        productGroupIds: [article.productGroup.id],
                        wholesalerArticleNumber: article.traderArticleNo,
                        availabilityState: {
                            id:
                                (quantityModifiers?.availability &&
                                    quantityModifiers.availability !== AvailabilityStatus.Unknown &&
                                    AvailabilityStatus[quantityModifiers.availability]) ||
                                "",
                            description: quantityModifiers?.availability?.toString() || "",
                        },
                    }
                }

                if (vehicleId) {
                    this.requestBody.vehicleId = vehicleId
                }

                this.sendEvent()

                break
            }
        }
    }
}
