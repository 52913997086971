import { CategoryType, GetArticlesRequest, GetArticlesResponse, SearchFilters, UserInteractionLog, Vehicle } from "@tm/models"
import { TmaEventTracking } from "../.."
import { uniqueId } from "../../.."
import { AnalyticsData, BaseTrackingModel, CatalogContextType } from "../../models/models"
import { ArticleListErpInfoShownPayload } from "../article-list-erp-info-shown/event-handle"
import { SearchFilter } from "./models"

const TMA_EVENT = "ARTICLE_LIST_FILTERED"
export interface ArticleListViewOptions {
    compactView: boolean
    showArticleImages: boolean
    showVehicleRecordsFilters: boolean
}

export const ArticleListFilteredEventHelper = {
    ArticleListFiltered: {
        Event: {
            IncreaseStepNumber: async () => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    bundle: "parts",
                    component: "searchtree",
                    action: "increase-step-number",
                    type: "click",
                    source: "selected-filter",
                    payload: true,
                })
            },
            SwapSearchType: async (category: CategoryType) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    bundle: "parts",
                    component: "area-swap",
                    action: "swap-category",
                    type: "click",
                    source: "selected-filter",
                    payload: { category },
                })
            },
        },
        List: {
            Results: async (
                request: GetArticlesRequest,
                result: GetArticlesResponse | undefined,
                worktaskId: string,
                url: string,
                searchedNodeId?: string
            ) => {
                const match = /nodeId=(\d+)/.exec(document.location.search)
                const latestSearchedNodeId = match?.last()

                if (!searchedNodeId || searchedNodeId == latestSearchedNodeId) {
                    // getArticles will be executed on every node click (from article list tree) so every request get his node id, so only the one with the same as in the url will be handle, if no nodeid is given it should be a direct or synonym search
                    TmaEventTracking.dispatchTrack({
                        tmaEvent: "ARTICLE_LIST_FILTERED",
                        bundle: "parts",
                        component: "repository",
                        action: "get-articles", // ["get-articles", "send-event"],
                        type: "request",
                        payload: { request, result, worktaskId },
                        source: url,
                        contextData: request.pageIndex
                            ? {
                                  paging: {
                                      pageIndex: request.pageIndex,
                                  },
                              }
                            : undefined,
                    })
                }

                const payload: ArticleListErpInfoShownPayload = { request, result }
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ArticleList_ErpInfo_Shown",
                    bundle: "parts",
                    component: "repository",
                    action: "get-articles",
                    type: "request",
                    payload,
                    source: url,
                    contextData: request.pageIndex
                        ? {
                              paging: {
                                  pageIndex: request.pageIndex,
                              },
                          }
                        : undefined,
                })
            },
            FilterChanged: async (data: AnalyticsData, source: string) => {
                TmaEventTracking.dispatchTrack({
                    action: "trackAnalyticsData", // source == "getOeReferenceFilters" ? "trackAnalyticsData" : ["trackAnalyticsData", "increase-step-number"], // maybe refactor after artile list refactoring, *1
                    bundle: "parts",
                    component: "helper",
                    payload: data,
                    source,
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    type: "request",
                })
            },
            ViewOptions: async (viewOptions: ArticleListViewOptions) => {
                const trackingData: BaseTrackingModel<{ viewOptions: ArticleListViewOptions }> = {
                    action: "viewoptions-set",
                    bundle: "parts",
                    component: "list",
                    source: "dropdown",
                    tmaEvent: TMA_EVENT,
                    type: "click",
                    payload: {
                        viewOptions,
                    },
                }

                TmaEventTracking.dispatchTrack(trackingData)
            },
            ViewOptionsChanged: async (viewOptions: ArticleListViewOptions) => {
                const trackingData: BaseTrackingModel<{ viewOptions: ArticleListViewOptions }> = {
                    // atm i dont know if we should do this. i like it a lot but complexity and array order is important, mb renaming to actionQueue would help 😣
                    action: "viewoptions-changed", // ["increase-step-number", "viewoptions-changed, "send-event"],
                    bundle: "parts",
                    component: "list",
                    source: "dropdown",
                    tmaEvent: TMA_EVENT,
                    type: "click",
                    payload: {
                        viewOptions,
                    },
                }

                TmaEventTracking.dispatchTrack(trackingData)
            },
            GetUserInteractionLog: (positionNumber?: number): UserInteractionLog | undefined => {
                const request = TmaEventTracking.getRequestData("ARTICLE_LIST_FILTERED")

                if (request?.searchStep) {
                    const log: UserInteractionLog = {
                        eventId: uniqueId(),
                        id: request.searchStep!.processId,
                        stepNumber: request.searchStep!.number,
                        fromPageNumber: request.articleList?.paging.pageIndex,
                        fromPositionNumber: positionNumber,
                        catalogContext: request.origin.context,
                        trigger: request.origin.trigger,
                        foundByQuery: request.querySearch?.query,
                    }
                    return log
                }

                return undefined
            },
        },
        Search: {
            AttachVehicle: (vehicle?: Vehicle) => {
                TmaEventTracking.dispatchTrack({
                    action: "cdm",
                    bundle: "parts",
                    type: "component",
                    source: "worktask-vehicle",
                    component: "list",
                    payload: vehicle,
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                })
            },
            SetSearchContext: (categoryType: CategoryType, query: string, workTaskId?: string, fromWidget?: boolean) => {
                TmaEventTracking.dispatchTrack(
                    {
                        tmaEvent: "ARTICLE_LIST_FILTERED",
                        action: "keyword-search-context",
                        bundle: "parts",
                        component: "navigation",
                        payload: {
                            categoryType,
                            query,
                            workTaskId,
                            fromWidget
                        },
                        source: "widget",
                        type: "render"
                    }
                )
            },
            Submit: (query: string, categoryType: CategoryType, searchFilter: SearchFilters) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "handleSearch",
                    bundle: "parts",
                    component: "SearchComponent",
                    payload: {
                        query,
                        categoryType,
                        searchFilter,
                    },
                    source: "searchfield",
                    type: "request",
                })
            },
            SubmitExternal: (query?: string) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "handleExternalSearch",
                    bundle: "api",
                    component: "post-message-controller",
                    payload: {
                        query
                    },
                    source: "searchfield",
                    type: "request"
                })
            },
            TreeChanged: (mode?: "fast-click" | "fast-click-2.0", thumbnailClicked?: boolean) => {
                TmaEventTracking.dispatchTrack({
                    // move this to business actions? function changeBreadcrumbs(breadcrumbs: Array<Models.TreeNode>, startSearch?: boolean): AsyncAction<ComponentActionType, WidgetState> {
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    bundle: "parts",
                    component: "searchtree",
                    action: "handleChangeBreadcrumbs",
                    type: "search",
                    source: thumbnailClicked ? "thumbnail" : "label",
                    payload: {
                        mode: mode || "fast-click-2.0",
                    },
                })
            },
            NodeSelected: (data: {
                mode?: "fast-click" | "fast-click-2.0" | "buttons" | "hide"
                catalogContext: CatalogContextType
                nodeId: number
                treeId?: number
            }) => {
                // not clear when and how and why to use this, it's implemented in handleSelectNode, but there is no further handling
                TmaEventTracking.dispatchTrack({
                    tmaEvent: TMA_EVENT,
                    action: "nodeSelected",
                    source: data.mode || "fast-click-2.0",
                    payload: data,
                    bundle: "parts",
                    component: "shared/tree",
                    type: "commit",
                })
            },
            AutoCompleteResults: (hits: string[]) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "autocomplete",
                    bundle: "parts",
                    component: "repository",
                    payload: { hits },
                    source: "product-group-search",
                    type: "request",
                })
            },
            SetSearchFilters: (searchFilters: Array<SearchFilter>) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "set-search-filters",
                    bundle: "parts",
                    component: "search",
                    payload: { searchFilters },
                    source: "directSearch",
                    type: "request",
                })
            },
        },
    },
    Tree: {
        SelectNode: (props: any, node: any, group: any) => {
            TmaEventTracking.dispatchTrack({
                action: "handleSelectNode",
                bundle: "parts",
                component: "shared/tree",
                payload: {
                    props,
                    node,
                    group,
                },
                source: props.mode || "fast-click-2.0",
                tmaEvent: "ARTICLE_LIST_FILTERED",
                type: "commit",
            })
        },
    },
    Parts: {
        Search: {
            AutoCompleteResults: (hits: string[]) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_LIST_FILTERED",
                    action: "autocomplete",
                    bundle: "parts",
                    component: "repository",
                    payload: { hits },
                    source: "product-group-search",
                    type: "request",
                })
            },
        },
        /**
         * @deprecated use ArticleListFiltered namespace instead of Parts
         */
        List: {
            /**
             * @deprecated use ArticleListFiltered namespace instead of Parts
             */
            ViewOptions: (viewOptions: ArticleListViewOptions) => {
                const trackingData: BaseTrackingModel<{ viewOptions: ArticleListViewOptions }> = {
                    action: "viewoptions-set",
                    bundle: "parts",
                    component: "list",
                    source: "dropdown",
                    tmaEvent: TMA_EVENT,
                    type: "click",
                    payload: {
                        viewOptions,
                    },
                }

                TmaEventTracking.dispatchTrack(trackingData)
            },
            /**
             * @deprecated use ArticleListFiltered namespace instead of Parts
             */
            GetUserInteractionLog: (positionNumber?: number): UserInteractionLog | undefined => {
                const request = TmaEventTracking.getRequestData("ARTICLE_LIST_FILTERED")

                if (request?.searchStep) {
                    const log: UserInteractionLog = {
                        eventId: uniqueId(),
                        id: request.searchStep!.processId,
                        stepNumber: request.searchStep!.number,
                        fromPageNumber: request.articleList?.paging.pageIndex,
                        fromPositionNumber: positionNumber,
                        catalogContext: request.origin.context,
                        trigger: request.origin.trigger,
                    }
                    return log
                }

                return undefined
            },
        },
    },
}
/**
 * @deprecated ArticleListFilteredEvents was renamed to ArticleListFilteredEventHelper
 */
export const ArticleListFilteredEvents = ArticleListFilteredEventHelper

/**
 * commentaries
 *1: oeFilter will be loaded after other filters are loaded, and i just want to increase the step number by one. so i decided oeFilter should not increase the stepnumber.
     i don't know what happens there -> dispatch(Actions.loadProductGroupAndSupplierFilters(true, false)) this function, located at /parts/src/components/list/business/index.ts, will be called multiple times
     and it will be refactored, so i won't take a deeper look
 */
