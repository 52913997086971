import { Article, OE } from "../article"
import { KeysByValue, PickByValue } from "../types"
import { IBasketMicros } from "./basket"
import { ICisMicros } from "./cis"
import { ICompilationsMicros } from "./compilations"
import { ICrmMicros } from "./crm"
import { IDMGDatMicros } from "./dmgDat"
import { IFastServiceMicros } from "./fastService"
import { IDmsMicros } from "./dms"
import { IErpMicros } from "./erp"
import { IEurotaxMicros } from "./eurotax"
import { IFastCalculatorNextMicros } from "./fastcalculatornext"
import { IDtcMicros } from "./fastDtc"
import { IMemoToolMicros } from "./memoTool"
import { INotificationMicros } from "./notifications"
import { IPartsMicros } from "./parts"
import { IStandaloneMicros } from "./standalone"
import { ITiresMicros } from "./tires"
import { IVehicleMicros } from "./vehicle"
import { IWheelsMicros } from "./wheels"
import { IWorktaskMicros } from "./worktask"
import { IMiscMicros } from "./misc"

export * from "./enumerations"

export type IMicros = {
    basket: IBasketMicros
    cis: ICisMicros
    compilations: ICompilationsMicros
    crm: ICrmMicros
    "dmg-dat": IDMGDatMicros
    dms: IDmsMicros
    erp: IErpMicros
    eurotax: IEurotaxMicros
    "fast-calculator-next": IFastCalculatorNextMicros
    "fast-dtc": IDtcMicros
    "fast-service": IFastServiceMicros
    "memo-tool": IMemoToolMicros
    "misc": IMiscMicros
    notifications: INotificationMicros
    parts: IPartsMicros
    standalone: IStandaloneMicros
    tyres: ITiresMicros
    vehicle: IVehicleMicros
    wheels: IWheelsMicros
    worktask: IWorktaskMicros
}

export interface IWithReplaceButtonMicro {
    "replace-button": ReplaceButton
}

export type ReplaceButton = {
    part: Article | OE.OePart
}

export type BundlesWithReplaceButtonMicro = KeysByValue<IMicros, IWithReplaceButtonMicro>
export type BundlesWithReplaceButtonMicroValues = PickByValue<IMicros, IWithReplaceButtonMicro>

/**
 * @memberof Models
 * @deprecated import from utils
 * @param value
 * @returns value is BundlesWithReplaceButtonMicro
 */
export function isBundleWithReplaceButtonMicro(value: string): value is BundlesWithReplaceButtonMicro {
    console.warn("depcrated, import from utils")
    return value == "fast-calculator-next"
        || value == "dmg-dat" 
        || value == "fast-service"
}
